import React from "react";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Instructions = () => {
  return (
    <Container>
      <Row>
        <h3> Instructions</h3>
        <ListGroup as="ol" numbered>
          <ListGroup.Item className="border-0">
            Click "Song Library" and select a song you want to learn
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            Click "Play Current Section" and listen to the music.
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            Try to play the music. You can click "Play Question Again" if you
            want to hear it again to help you sound it out.
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            Once you think you've figured out how to play the melody, click
            "test me". You will hear 4 metronome ticks. After that, play the
            melody or chord progression. If you don't have a real musical
            instrument, but you still want to play, you can use a piano app on
            your phone. (The first time you click "test me", you'll need to give
            the website permission to use your microphone)
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            Make sure to pay attention to the tempo, and when you start. If you
            play too early/late, or too fast/slow, it will have a hard time
            recognizing what you played.
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            Once you've figured out one section, go to the next, and repeat
            until you've learned the whole song by ear!
          </ListGroup.Item>
          <ListGroup.Item className="border-0">
            As you practice, you'll get faster, so you'll be able to play
            anything you want on the first try, just from knowing what it sounds
            like
          </ListGroup.Item>
        </ListGroup>
      </Row>
    </Container>
  );
};

export default Instructions;
