// This is a clone of fucntionality form Tonal.
// I am getting import errors so I'm just removing the dependency
// and writing it myself

// write a function that takes a midi note as input, and outputs the note name
const midiToNoteName = (midiNote: number): string => {
  const noteNames = [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ];
  const octave = Math.floor(midiNote / 12) - 1;
  const noteName = noteNames[midiNote % 12];
  return `${noteName}${octave}`;
};

const noteNameToMidi = (noteName: string): number => {
  const noteNames: any = {
    C: 0,
    "C#": 1,
    Db: 1,
    D: 2,
    "D#": 3,
    Eb: 3,
    E: 4,
    F: 5,
    "F#": 6,
    Gb: 6,
    G: 7,
    "G#": 8,
    Ab: 8,
    A: 9,
    "A#": 10,
    Bb: 10,
    B: 11,
  };
  const octave = parseInt(noteName[noteName.length - 1]);
  const note: string = noteName.slice(0, noteName.length - 1);
  const noteIndex = noteNames[note];
  return (octave + 1) * 12 + noteIndex;
};

const Midi = {
  midiToNoteName: midiToNoteName,
  noteNameToMidi: noteNameToMidi,
};

export default Midi;
