import myMidi from "./Midi";
const { Midi } = require("@tonejs/midi");

const filenameToAudioBlob = async (filename: string) => {
  // TODO is "no-cors" the right way to solve this?
  let blob = await fetch(filename, { mode: "no-cors" }).then((r) => r.blob());
  return blob;
};

const randomChoice = (arr: any[]) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const createMidiScale = (keyMidi: number, bpm: number) => {
  const scale = [0, 2, 4, 5, 7, 9, 11, 12];
  const scaleMidi = scale.map((x) => x + keyMidi);
  let midi = [];
  for (let i = 0; i < 8; i++) {
    midi.push({
      pitchMidi: scaleMidi[i],
      startTimeSeconds: (i * 60) / bpm,
      durationSeconds: 60 / bpm,
      amplitude: 1,
    });
  }
  return {
    melodyMidi: midi,
    melodyNotes: scaleMidi.map((x) => myMidi.midiToNoteName(x)),
  };
};

const randomChoiceList = (arr: any[], num: number) => {
  const result = [];
  for (let i = 0; i < num; i++) {
    result.push(randomChoice(arr));
  }
  return result;
};

//write a function that saves a midi file
const saveMidi = (midi: any, filename: string) => {
  const blob = new Blob([midi.toArray()], { type: "audio/midi" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.id = "midiFile";
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  return a;
};

const printMidi = (midi: any, message: string = "printMidi") => {
  const midiClone = midi.map((x: any) => {
    return {
      pitchMidi: x.pitchMidi,
      start: x.startTimeSeconds,
      amplitude: x.amplitude,
      duration: x.durationSeconds,
    };
  });
};

const alignVexflowNoteToKey = (note: string, key: string) => {
  // remove the sharp sign because it's already sharp in the key
  if (note.length == 1) {
    return note;
  }
  const flatEquivalence: any = {
    ab: "g",
    bb: "a",
    db: "c",
    eb: "d",
    gb: "f",
  };
  const sharpKeys = ["G", "D", "A", "E", "B", "F#", "C#"];
  if (sharpKeys.includes(key)) {
    return flatEquivalence[note];
  } else {
    return note;
  }
};

const noteNameToVexflow = (noteName: string, key: string): string => {
  const octave = noteName[noteName.length - 1];
  let note = noteName.slice(0, noteName.length - 1).toLowerCase();

  note = alignVexflowNoteToKey(note, key);
  return `${note}/${octave}`;
};

const keySignatureOffset = {
  C: 0,
  G: 1,
  D: 2,
  A: 3,
  E: 4,
  B: 5,
  "F#": 6,
  F: 1,
  Bb: 2,
  Eb: 3,
  Ab: 4,
  Db: 5,
  Gb: 6,
};

const midiTestCase1 = [
  {
    pitchMidi: 60,
    startTimeSeconds: 0.25,
    durationSeconds: 0.25,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeSeconds: 0.5,
    durationSeconds: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeSeconds: 0.5,
    durationSeconds: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeSeconds: 1.5,
    durationSeconds: 0.25,
    amplitude: 1,
  },
];

const midiTestCase2 = [
  {
    pitchMidi: 60,
    startTimeSeconds: 0,
    durationSeconds: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 62,
    startTimeSeconds: 0.5,
    durationSeconds: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeSeconds: 1,
    durationSeconds: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 65,
    startTimeSeconds: 1.5,
    durationSeconds: 0.5,
    amplitude: 1,
  },
];

const getBeatCount = (midi: any, bpm: number) => {
  const lastNote = midi[midi.length - 1];
  return ((lastNote.startTimeSeconds + lastNote.durationSeconds) / 60) * bpm;
};

const countWhiteKeys = (lowMidiNote: number, highMidiNote: number) => {
  // counts the number of white keys between lowMidiNote and highMidiNote, inclusive
  // assumes lowMidiNote <= highMidiNote
  const whiteKeys = [0, 2, 4, 5, 7, 9, 11];
  let count = 0;
  for (let i = lowMidiNote; i <= highMidiNote; i++) {
    if (whiteKeys.includes(i % 12)) {
      count++;
    }
  }
  return count;
};

const filterMidiToMeasureRange = (
  midi: any,
  startMeasure: number,
  endMeasure: number,
  bpm: number,
  beatsPerMeasure: number,
  noteFilterMode: string = "fullSong",
  originalSongBPM: number = 0
) => {
  const startBeat = startMeasure * beatsPerMeasure;
  const endBeat = endMeasure * beatsPerMeasure;
  const startSeconds = (startBeat / originalSongBPM) * 60;
  const endSeconds = (endBeat / originalSongBPM) * 60;
  midi = midi
    .filter((note: any) => {
      return (
        note.startTimeSeconds >= startSeconds &&
        note.startTimeSeconds < endSeconds
      );
    })
    .filter((note: any) => {
      if (noteFilterMode == "fullSong") {
        return true;
      } else if (noteFilterMode == "treble") {
        if (note.clef !== undefined) {
          return note.clef == "treble";
        } else {
          return note.pitchMidi >= 60;
        }
      } else if (noteFilterMode == "bass") {
        if (note.clef !== undefined) {
          return note.clef == "bass";
        } else {
          return note.pitchMidi < 60;
        }
      }
    })
    .map((note: any) => {
      return {
        ...note,
        durationSeconds: Math.min(
          endSeconds - note.startTimeSeconds,
          note.durationSeconds
        ),
        startTimeSeconds: note.startTimeSeconds - startSeconds,
      };
    });
  if (originalSongBPM > 0) {
    midi = midi.map((note: any) => {
      return {
        ...note,
        startTimeSeconds: (note.startTimeSeconds / bpm) * originalSongBPM,
        durationSeconds: (note.durationSeconds / bpm) * originalSongBPM,
      };
    });
  }
  return midi;
};

const convertKeyToBaseNote = (key: string, quality: string) => {
  if (quality == "major") {
    return key;
  } else if (quality == "minor") {
    const noteNames: any = {
      C: "A",
      Db: "Bb",
      D: "B",
      Eb: "C",
      E: "C#",
      F: "D",
      Gb: "Eb",
      G: "E",
      Ab: "F",
      A: "F#",
      Bb: "G",
      B: "G#",
    };
    return noteNames[key];
  }
};

const convertMidFileToMidi = (midi: any) => {
  const bpm = parseInt(midi.header.tempos[0].bpm.toFixed(0));
  const beatsPerMeasure = midi.header.timeSignatures[0].timeSignature[0];
  const key = midi.header.keySignatures[0].key;
  const quality = midi.header.keySignatures[0].scale;
  const ppq = midi.header.ppq;

  const trackMidi = midi.tracks.map((track: any) => {
    return track.notes
      .map((note: any) => {
        return {
          pitchMidi: note.midi,
          startTimeSeconds: (note.ticks / ppq / bpm) * 60,
          durationSeconds: (note.durationTicks / ppq / bpm) * 60,
          amplitude: 1,
        };
      })
      .filter((note: any) => {
        return note.durationSeconds > 0;
      });
  });

  const highestNote = trackMidi.map((track: any) =>
    Math.max(...track.map((note: any) => note.pitchMidi))
  );
  const trebleTrackIndex = highestNote.indexOf(Math.max(...highestNote));
  let trebleTrack = trackMidi[trebleTrackIndex];
  trebleTrack = trebleTrack.map((note: any) => {
    return {
      ...note,
      clef: "treble",
    };
  });
  if (trackMidi.length === 1) {
    return trebleTrack;
  }
  const bassTrackIndex = highestNote.indexOf(Math.min(...highestNote));
  let bassTrack = trackMidi[bassTrackIndex];
  bassTrack = bassTrack.map((note: any) => {
    return {
      ...note,
      clef: "bass",
    };
  });
  return {
    midi: [...trebleTrack, ...bassTrack].sort((a: any, b: any) => {
      return a.startTimeSeconds - b.startTimeSeconds;
    }),
    bpm: bpm,
    beatsPerMeasure: beatsPerMeasure,
    key: key,
  };
};

const fetchMidiFromS3 = async (filename: string) => {
  filename = filename.replace(/ /g, "_");
  const url = `https://eartrainerpublic.s3.us-west-2.amazonaws.com/midi/${filename}.mid`;
  const response = await fetch(url);
  let midi: any = await response.arrayBuffer();
  midi = new Midi(midi);

  return midi;
};

export {
  filenameToAudioBlob,
  randomChoiceList,
  saveMidi,
  printMidi,
  noteNameToVexflow,
  alignVexflowNoteToKey,
  keySignatureOffset,
  midiTestCase1,
  midiTestCase2,
  getBeatCount,
  countWhiteKeys,
  filterMidiToMeasureRange,
  convertKeyToBaseNote,
  fetchMidiFromS3,
  convertMidFileToMidi,
  createMidiScale,
};
