import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import EmailCollectionForm from "./emailCollectionForm";

const LegalDisclaimer = () => {
  return (
    <Container className="pb-5">
      <h2>Disclaimer</h2>
      <p>
        I am building All By Ear in public. This means I'm sharing an extremely
        early version that is still is missing a lot of features. I'm working as
        hard as I can to add new features based on feedback from what you want.{" "}
      </p>
      <p>
        By reading this disclaimer you are legally agreeing not to sue me for
        making a really ugly, barely functional website.
      </p>
      <h4>Main goal:</h4>
      <p>
        Make a web app that can help you learn any song. Ideally you would
        upload a music file or link to a youtube video, then this app would
        create exercises for you that help you sound out the song step by step,
        including both the melody and harmony. This might be difficult to do, so
        instead I might have a library of songs that you can choose from. To get
        there, I'm starting with exercises for random melodies/chord
        progressions, and building up complexity step by step (different keys,
        rhythms, etc.).
      </p>
      <p>
        Here's the list of upcoming features. Most of these should be pretty
        quick to implement, so check back soon to watch the progress! Or, sign
        up for email notifications about new releases. People who sign up early
        will get $10 credits towards a premium subscription (once Premium
        subscriptions exist)
      </p>
      <EmailCollectionForm />

      <ListGroup as="ol" numbered>
        <ListGroup.Item className="border-0">
          The machine learning model that writes down the notes you play is not
          100% yet. Use it as a guide, but it's not perfect. I'm working on
          improving it. Also, in the future this will support midi input, so you
          can connect your instrument to your computer and play it directly into
          the app.
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Currently the staff music does not correctly show tied notes. It just
          cuts them short (the piano visualizer shows it right though). Fix this
          so that it can show ties.
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Make sure it works on all browsers and devices (Currently, we
          recommend using Chrome, other browsers have not been fully checked)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Choose different song lengths (so you can start with 1 note, then 2,
          and work up to more)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Choose any key for the song (right now it's just C major)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Choose Major/Minor for the exercises.
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Different time signatures and rhythms for the notes (right now all
          notes that aren't from the song library are quarter notes)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Right now the "melodies" are just randomly chosen notes, they don't
          really sound like music. They will soon sound more musical
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Choose different difficulty levels (e.g. smaller/larger intervals in
          the melody, rare chords)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Add combined Melody + Chord progression exercises where you need to
          sound out both together
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          For chords, add an option to just use the chord names instead of
          musical staff notation (this will be better for people who want to use
          this for guitar)
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Make the website not look incredibly ugly
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          Add more songs to the song library
        </ListGroup.Item>
      </ListGroup>
      <p>
        If you have any feedback, please let me know! For example, if there's a
        feature you want that isn't in the list, or the feature you want is late
        in the list and you want it to be earlier, let me know and I'll
        prioritize it. You can message me at rosminebuilds@gmail.com
      </p>
    </Container>
  );
};

export default LegalDisclaimer;
