import Vex, { Voice } from "vexflow";
import { VEX_FORMAT_NOTE_LENGTH_MAP } from "./doStuff";
import { keySignatureOffset } from "./utils";
import Midi from "./Midi";
const { Stave, StaveNote, Beam, Formatter, Renderer } = Vex;
const { Factory } = Vex.Flow;

let x = 120;
let y = 80;

function appendSystem(factory, width) {
  const system = factory.System({ x, y, width, spaceBetweenStaves: 10 });
  x += width;
  return system;
}

const getMusicExplanation = (parentDiv) => {
  const div = document.createElement("div");
  div.setAttribute("id", parentDiv + "musicExplanation");
  const text =
    parentDiv == "correctNotes" ? "Correct Notes:" : "You Missed These Notes:";
  div.innerHTML = `<h4 class="text-center">${text}</h4>`;

  return div;
};

const makeFactory = (elementId, backend, width = 450, height = 140) => {
  return new Factory({ renderer: { elementId, backend, width, height } });
};

const id = (id) => document.getElementById(id);

const concat = (a, b) => a.concat(b);

const getBeamStemDirection = (toBeam, options) => {
  const pitches = toBeam.map((x) => Midi.noteNameToMidi(x.split("/")[0]));
  const pitchMean = pitches.reduce((a, b) => a + b, 0) / pitches.length;
  // if (options['clef'] == 'bass') {
  //   const numberAboveMid = pitches.filter(x => x > 50).length;
  //   if (numberAboveMid > toBeam.length / 2) {
  //     return 'up';
  //   }
  //   return 'down';
  // } else {
  //   const numberAboveMid = pitches.filter(x => x >= 71).length;
  //   if (numberAboveMid > toBeam.length / 2) {
  //     return 'up';
  //   }
  //   return 'down';
  // }
  const midPoint = options["clef"] == "bass" ? 50 : 71;
  return pitchMean > midPoint ? "down" : "up";
};

const measureSplitter = (measure) => {
  const notes = [];
  let currentNote = "";
  let inParentheses = false;
  for (let i = 0; i < measure.length; i++) {
    if (measure[i] == "(") {
      inParentheses = true;
      currentNote += measure[i];
    } else if (measure[i] == ")") {
      inParentheses = false;
      currentNote += measure[i];
    } else if (measure[i] == ",") {
      if (!inParentheses) {
        notes.push(currentNote);
        currentNote = "";
      }
    } else if (measure[i] == " ") {
      if (inParentheses) {
        currentNote += measure[i];
      }
    } else {
      currentNote += measure[i];
    }
  }
  notes.push(currentNote);
  return notes;
};

const beamMeasure = (measure, notes, beam, options = {}) => {
  // todo also handle ties
  options["stem"] = "up";
  const noteSequence = measureSplitter(measure);
  let toBeam = [];
  let toReturn = [];
  let currentBeatCount = 0;
  let i = 0;
  let counter = 0;
  let beamIndex = 0;
  noteSequence.push("0/0");
  while (i + beamIndex < noteSequence.length) {
    const note = noteSequence[i + beamIndex];
    const noteSplit = note.split("/");
    const noteLength = noteSplit[1];
    const isRest = noteSplit[noteSplit.length - 1] == "r";
    counter += 1;
    if (counter > 100) {
      break;
    }
    if (
      toBeam.length > 0 &&
      (i + beamIndex == noteSequence.length - 1 ||
        currentBeatCount >= 2 ||
        !["8", "16"].includes(noteLength) ||
        isRest)
    ) {
      while (toBeam.length > 1) {
        try {
          options["stem"] = getBeamStemDirection(toBeam, options);
          toReturn.push(beam(notes(toBeam.join(", "), options)));
          i += toBeam.length;
          toBeam = [];
          currentBeatCount = 0;
        } catch {
          toBeam = toBeam.slice(0, toBeam.length - 1);
        }
      }
      if (toBeam.length == 1) {
        toReturn.push(notes(toBeam[0], options));
        i += 1;
      }
      toBeam = [];
      currentBeatCount = 0;
      beamIndex = 0;
    } else {
      if (["8", "16"].includes(noteLength) && !isRest) {
        toBeam.push(note);
        currentBeatCount += VEX_FORMAT_NOTE_LENGTH_MAP[noteLength];
        beamIndex += 1;
      } else {
        if (noteLength == "0") {
          i += 1;
        } else {
          toReturn.push(notes(note, options));
          i += 1;
        }
      }
    }
  }
  return toReturn.reduce(concat); //, { stem: "up" }
};

const countTimesteps = (measures) => {
  let count = 0;
  for (let measure of measures) {
    count += measureSplitter(measure).length;
  }
  return count;
};

const drawNotes = (
  measures,
  bassMeasures,
  parentDiv,
  beatsPerMeasure,
  key,
  widthParams = {}
) => {
  // example of multiple measures, treble and bass
  // https://github.com/0xfe/vexflow/blob/master/tests/bach_tests.ts
  let saveWidthParams = {
    totalWidth: 0,
    measures: [],
  };
  const divId = parentDiv + "sheetMusic";
  let useBase = false;
  if (bassMeasures.length > 0) {
    useBase = true;
  }

  const div = document.createElement("div");
  div.classList.add("d-flex", "justify-content-center");
  if (measures.length === 0) {
    div.innerHTML = "<p>No notes detected</p>";
  }
  div.setAttribute("id", divId);
  getMusicExplanation(parentDiv);
  document
    .getElementById(parentDiv)
    .appendChild(getMusicExplanation(parentDiv));
  document.getElementById(parentDiv).appendChild(div);
  const backend = Vex.Flow.Renderer.Backends.SVG;
  const minMeasureWidth = 200;
  let noteCount = countTimesteps(measures);
  console.log(measures);
  console.log(noteCount);
  if (useBase) {
    noteCount = Math.max(noteCount, countTimesteps(bassMeasures));
  }

  let widthFactor = noteCount > 4 ? 45 : 75;
  if (noteCount > 16) {
    widthFactor = 30;
  }
  let totalWidth =
    measures
      .map((measure) => {
        return Math.max(
          countTimesteps([measure]) * widthFactor,
          minMeasureWidth
        );
      })
      .reduce((a, b) => a + b) + 25;
  if (useBase) {
    totalWidth = Math.max(
      totalWidth,
      bassMeasures
        .map((measure) => {
          return Math.max(
            countTimesteps([measure]) * widthFactor,
            minMeasureWidth
          );
        })
        .reduce((a, b) => a + b) + 25
    );
  }

  console.log(totalWidth);
  // debugger;
  if (widthParams["totalWidth"] !== undefined) {
    totalWidth = widthParams["totalWidth"];
  }
  saveWidthParams["totalWidth"] = totalWidth;

  // const f = makeFactory(divId, backend, totalWidth, useBase ? 150 : 120, 100);
  const f = makeFactory(divId, backend, totalWidth, 250);
  const score = f.EasyScore({ throwOnError: true });

  const voice = score.voice.bind(score);
  const notes = score.notes.bind(score);
  const beam = score.beam.bind(score);

  let x = 0;
  let y = 0;

  function appendSystem(width) {
    const system = f.System({
      x,
      y,
      width,
      spaceBetweenStaves: 9,
      height: 1000,
    });
    x += width;
    return system;
  }
  for (let i = 0; i < measures.length; i++) {
    let measureWidth = Math.max(
      countTimesteps([measures[i]]) * widthFactor,
      minMeasureWidth
    );

    if (
      widthParams["measures"] !== undefined &&
      widthParams["measures"][i] !== undefined
    ) {
      measureWidth = widthParams["measures"][i];
    }
    saveWidthParams["measures"].push(measureWidth);
    if (useBase) {
      measureWidth = Math.max(
        measureWidth,
        countTimesteps([bassMeasures[i]]) * widthFactor
      );
    }
    let system = appendSystem(measureWidth);
    if (i == 0) {
      system
        .addStave({
          voices: [voice(beamMeasure(measures[i], notes, beam))],
        })
        .addClef("treble")
        .addKeySignature(key)
        .addTimeSignature("4/4");
      if (useBase) {
        system
          .addStave({
            voices: [
              voice(
                beamMeasure(bassMeasures[i], notes, beam, {
                  clef: "bass",
                })
              ),
            ],
          })
          .addClef("bass")
          .addKeySignature(key)
          .addTimeSignature("4/4");
      }
    } else {
      system.addStave({
        voices: [voice(beamMeasure(measures[i], notes, beam))],
      });
      if (useBase) {
        system.addStave({
          voices: [
            voice(
              beamMeasure(bassMeasures[i], notes, beam, {
                clef: "bass",
              })
            ),
          ],
        });
      }
    }
  }
  f.draw();
  return saveWidthParams;
};

export default drawNotes;
