import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { filterMidiToMeasureRange } from "./utils";

// const pokemonMidi = [
//   // measure 1
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 0.25,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 0.5,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },

//   {
//     pitchMidi: 64,
//     startTimeSeconds: 0.75,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 1,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 1.75,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   // measure 2
//   {
//     pitchMidi: 62,
//     startTimeSeconds: 2,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 59,
//     startTimeSeconds: 2.5,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 55,
//     startTimeSeconds: 2.75,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 55,
//     startTimeSeconds: 3.75,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   //measure 3
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 4,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 64,
//     startTimeSeconds: 4.5,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 62,
//     startTimeSeconds: 5,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 60,
//     startTimeSeconds: 5.25,
//     durationSeconds: 0.25,
//     amplitude: 1,
//   },
//   {
//     pitchMidi: 62,
//     startTimeSeconds: 5.5,
//     durationSeconds: 0.5,
//     amplitude: 1,
//   },
//   // measure 4

// ];

const pokemonBeatsBass = [
  // measure 1
  {
    pitchMidi: 45,
    startTimeBeats: 0,
    durationBeats: 4,
    amplitude: 1,
  },
  // measure 2
  {
    pitchMidi: 43,
    startTimeBeats: 4,
    durationBeats: 4,
    amplitude: 1,
  },
  // measure 3
  {
    pitchMidi: 45,
    startTimeBeats: 8,
    durationBeats: 4,
    amplitude: 1,
  },
  // measure 4
  {
    pitchMidi: 43,
    startTimeBeats: 12,
    durationBeats: 4,
    amplitude: 1,
  },
  // measure 5
  {
    pitchMidi: 53,
    startTimeBeats: 16,
    durationBeats: 4,
    amplitude: 1,
  },
  // measure 6
  {
    pitchMidi: 48,
    startTimeBeats: 20,
    durationBeats: 4,
    amplitude: 1,
  },

  // measure 7
  {
    pitchMidi: 48,
    startTimeBeats: 24,
    durationBeats: 2,
    amplitude: 1,
  },
  {
    pitchMidi: 50,
    startTimeBeats: 26,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 52,
    startTimeBeats: 27,
    durationBeats: 1,
    amplitude: 1,
  },
];

const pokemonBeatsTreble = [
  // measure 1
  {
    pitchMidi: 64,
    startTimeBeats: 0.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 1,
    durationBeats: 0.5,
    amplitude: 1,
  },

  {
    pitchMidi: 64,
    startTimeBeats: 1.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 2,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 3.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  // measure 2

  {
    pitchMidi: 62,
    startTimeBeats: 4,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 59,
    startTimeBeats: 5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 55,
    startTimeBeats: 5.5,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 55,
    startTimeBeats: 7.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  //measure 3
  {
    pitchMidi: 64,
    startTimeBeats: 8,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 9,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 62,
    startTimeBeats: 10,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeBeats: 10.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 62,
    startTimeBeats: 11,
    durationBeats: 1,
    amplitude: 1,
  },
  // measure 4
  // measure 5
  {
    pitchMidi: 65,
    startTimeBeats: 17,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 65,
    startTimeBeats: 17.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 65,
    startTimeBeats: 18,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 65,
    startTimeBeats: 19.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  // measure 6
  {
    pitchMidi: 64,
    startTimeBeats: 20,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 62,
    startTimeBeats: 21,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeBeats: 21.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeBeats: 23.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  //measure 7
  {
    pitchMidi: 64,
    startTimeBeats: 24,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 25,
    durationBeats: 1,
    amplitude: 1,
  },
  {
    pitchMidi: 62,
    startTimeBeats: 26,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 60,
    startTimeBeats: 26.5,
    durationBeats: 0.5,
    amplitude: 1,
  },
  {
    pitchMidi: 64,
    startTimeBeats: 27,
    durationBeats: 1,
    amplitude: 1,
  },
  // measure 8
];

const blackNotesBeats = [];
for (let i = 0; i < 12; i++) {
  if ([1, 3, 6, 8, 10].includes(i)) {
    blackNotesBeats.push({
      pitchMidi: 60 + i,
      startTimeBeats: i / 2,
      durationBeats: 0.5,
      amplitude: 1,
    });
  }
}

const convertBeatMidiToMidi = (beatMidi, bpm) => {
  return beatMidi.map((note) => {
    return {
      pitchMidi: note.pitchMidi,
      startTimeSeconds: (note.startTimeBeats * 60) / bpm,
      durationSeconds: (note.durationBeats * 60) / bpm,
      amplitude: note.amplitude,
    };
  });
};

const mergeTrebleBass = (treble, bass, bpm) => {
  let merged = [
    ...convertBeatMidiToMidi(treble, bpm).map((x) => ({
      ...x,
      clef: "treble",
    })),
    ...convertBeatMidiToMidi(bass, bpm).map((x) => ({ ...x, clef: "bass" })),
  ];
  merged = merged.sort((a, b) => a.startTimeSeconds - b.startTimeSeconds);
  return merged;
};

const pokemonMidi = mergeTrebleBass(pokemonBeatsTreble, pokemonBeatsBass, 120);
const blackNotes = convertBeatMidiToMidi(blackNotesBeats, 120);

const filterSongToGetExercise = (
  song,
  startMeasure,
  endMeasure,
  bpm,
  beatsPerMeasure
) => {
  let midi;
  if (song === "pokemon") {
    midi = pokemonMidi;
  }
  const filteredMidi = filterMidiToMeasureRange(
    midi,
    startMeasure,
    endMeasure,
    bpm,
    beatsPerMeasure
  );
  return { midi, filteredMidi };
};

const SongLibrary = (props) => {
  const [song, setSong] = useState(props.song);
  const [currentSection, setCurrentSection] = useState(props.currentSection);

  useEffect(() => {
    setSong(props.song);
    setCurrentSection(props.currentSection);
  }, [props.song, props.currentSection]);

  const options = [
    <option key={"select"}>Select Song</option>,
    props.availableSongs.map((song) => (
      <option key={song} value={song}>
        {song}
      </option>
    )),
  ];

  return (
    <Container>
      <Form.Select
        aria-label="Select Song"
        onChange={(e) => {
          props.setSong(e.target.value);
        }}
      >
        {options}
      </Form.Select>
      <Row>Current Section: {props.currentSection + 1}</Row>
      <Row>
        <Col>
          <Button onClick={props.prevSection}>Previous Section</Button>
        </Col>
        <Col>
          <Button onClick={props.nextSection}>Next Section</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SongLibrary;
export { filterSongToGetExercise, pokemonMidi, blackNotes };
