import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Midi from "./Midi";
import { countWhiteKeys } from "./utils";
import { filterMidiToFirstNotes } from "./doStuff";

const UpcomingNotes = (props) => {
  const [windowWidth, setWindowWidth] = React.useState(props.width);
  const [windowHeight, setWindowHeight] = React.useState(props.height);
  const [noteSequence, setNoteSequence] = React.useState(props.noteSequence);
  const sampler = props.sampler;
  const [correctNotesMidi, setcorrectNotesMidi] = React.useState(
    props.correctNotesMidi
  );

  useEffect(() => {
    setcorrectNotesMidi(props.correctNotesMidi);
  }, [props.correctNotesMidi]);

  const PIXELS_PER_SECOND = 200;
  const numberWhiteNotes = countWhiteKeys(
    props.noteRange.first,
    props.noteRange.last
  );
  const testNoteSequence = [
    ["C4", "E4", "G4"],
    ["C4", "E4", "G4"],
    ["F4", "A4", "C5"],
    ["G4", "B4", "D5"],
  ];

  const scaleDegrees = {
    60: 1,
    62: 2,
    64: 3,
    65: 4,
    67: 5,
    69: 6,
    71: 7,
    72: 8,
    74: 9,
    76: 10,
    77: 11,
    79: 12,
  };

  const blackNoteOffsets = {
    1: 0.53,
    3: 0.8,
    6: 0.5,
    8: 0.7,
    10: 0.85,
  };

  const getNoteInitialPosition = (midiNote, startTime, duration) => {
    let positionFromLeft = countWhiteKeys(props.noteRange.first, midiNote) - 1;
    const isBlackKey = [1, 3, 6, 8, 10].includes(midiNote % 12);
    const whiteNoteWidth = windowWidth / numberWhiteNotes;
    const noteWidth = whiteNoteWidth * (isBlackKey ? 0.58 : 1);
    const offset = isBlackKey ? blackNoteOffsets[midiNote % 12] : 0;
    const left = (positionFromLeft + offset) * whiteNoteWidth;
    const bottom = PIXELS_PER_SECOND * startTime;
    const height = PIXELS_PER_SECOND * duration;
    return { left, bottom, height, noteWidth };
  };

  const startOneNote = (midiNote, startTime, duration) => {
    const stepSize = 2;
    const noteBar = document.createElement("div");
    const upcomingNotes = document.getElementById("upcomingNotes");
    upcomingNotes.appendChild(noteBar);
    const initialPosition = getNoteInitialPosition(
      midiNote,
      startTime,
      duration
    );
    noteBar.style.position = "absolute";
    noteBar.style.height = initialPosition.height + "px";
    noteBar.style.backgroundColor = "green";
    noteBar.style.border = "1px solid black";
    noteBar.style.borderRadius = "5px";
    noteBar.style.width = initialPosition.noteWidth + "px";
    var pos = initialPosition.bottom;
    var id = setInterval(frame, 10);
    function frame() {
      if (pos <= stepSize && pos > 0) {
        props.playNote(midiNote);
      }
      if (pos + initialPosition.height <= 0 && pos > -stepSize) {
        props.stopNote(midiNote);
      }
      if (pos + initialPosition.height <= 0) {
        clearInterval(id);
        upcomingNotes.removeChild(noteBar);
      } else {
        pos -= stepSize;
        noteBar.style.bottom = pos + "px";
        noteBar.style.left = initialPosition.left + "px";
      }
    }
  };

  const addToObj = (obj, key, value) => {
    if (obj[key]) {
      obj[key].push(value);
    } else {
      obj[key] = [value];
    }
  };

  const playNoteSequence = (noteSequence, bpm, delayBeats) => {
    // noteSequence is an array of arrays.
    // each inner array is the notes played at that beat in C5 format
    const buffer = 100;

    const noteLengthSeconds = 60 / bpm;
    const delaySeconds = noteLengthSeconds * delayBeats;
    //group note sequence by start time
    // const beatToNotesMap = {};
    // for (let note of noteSequence) {
    //   if (beatToNotesMap[note.startTimeSeconds]) {
    //     beatToNotesMap[note.startTimeSeconds].push(note);
    //   } else {
    //     beatToNotesMap[note.startTimeSeconds] = [note];
    //   }
    // }

    const startTimeMidiMap = {};
    const endTimeMidiMap = {};

    for (let note of noteSequence) {
      let startTime = note.startTimeSeconds;
      let duration = note.durationSeconds;
      addToObj(startTimeMidiMap, startTime.toFixed(4), note.pitchMidi);
      addToObj(
        endTimeMidiMap,
        (startTime + duration).toFixed(4),
        note.pitchMidi
      );
      startOneNote(note.pitchMidi, startTime + delaySeconds, duration);
    }
    for (let endTime in endTimeMidiMap) {
      setTimeout(() => {
        props.removeActiveNotes(endTimeMidiMap[endTime]);
      }, (delaySeconds + parseFloat(endTime)) * 1000 - buffer);
    }
    for (let startTime in startTimeMidiMap) {
      setTimeout(() => {
        props.addActiveNotes(startTimeMidiMap[startTime]);
      }, (delaySeconds + parseFloat(startTime)) * 1000);
    }
  };

  const showAnswer = () => {
    if (correctNotesMidi.length > 0) {
      playNoteSequence(correctNotesMidi, props.bpm, props.beatDelay);
      console.log(correctNotesMidi);
      props.showAnswerSheetMusic();
    } else {
      alert("please play a question first");
    }
  };

  const showFirstNotes = () => {
    if (correctNotesMidi.length > 0) {
      const firstNotes = filterMidiToFirstNotes(correctNotesMidi);

      playNoteSequence(firstNotes, props.bpm, props.beatDelay);
      props.showFirstNotes();
    } else {
      alert("please play a question first");
    }
  };

  return (
    <div>
      <Button className="m-2" onClick={() => showAnswer()}>
        Show Answer
      </Button>
      <Button className="m-2" onClick={() => showFirstNotes()}>
        Hint: Show First Notes
      </Button>
      <div
        id="upcomingNotes"
        style={{
          height: windowHeight,
          width: windowWidth,
          borderWidth: 1,
          borderColor: "black",
          borderStyle: "solid",
          position: "relative",
          overflow: "hidden",
        }}
      ></div>
    </div>
  );
};

export default UpcomingNotes;
