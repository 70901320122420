// import { MusicRNN } from "@magenta/music";
// TODO if I uncomment this ^ and add magenta music, then I can't
// do transcription with Basic Pitch
import { randomChoiceList } from "./utils";
import Midi from "./Midi";

// use https://logan-takahashi.medium.com/how-i-made-a-neural-melody-maker-with-magenta-js-cbeed2740b2a
// as a guide

// TODO run in web worker/service worker https://magenta.github.io/magenta-js/music/#musicrnn

// const sequence = {
//   ticksPerQuarter: 220,
//   totalTime: 1,
//   timeSignatures: [
//     {
//       time: 0,
//       numerator: 4,
//       denominator: 4,
//     },
//   ],
//   tempos: [
//     {
//       time: 0,
//       qpm: 120,
//     },
//   ],
//   notes: [
//     { pitch: "C4", startTime: 0, endTime: 1 },
//     // { pitch: "Gb4", startTime: 0, endTime: 1 },
//     // { pitch: "F4", startTime: 1, endTime: 3.5 },
//   ],
// };

// const quantizedSequence = mm.sequences.quantizeNoteSequence(sequence, 1);

// const loadMelodyRnn = () => {
//   const melodyrnn = new MusicRNN(
//     // "https://storage.googleapis.com/magentadata/js/checkpoints/music_rnn/melody_rnn"
//     "https://storage.googleapis.com/magentadata/js/checkpoints/music_rnn/basic_rnn"
//   );
//   return melodyrnn;
// };

// const mel_teapot = {
//   notes: [
//     { pitch: 69, quantizedStartStep: 0, quantizedEndStep: 2, program: 0 },
//     { pitch: 71, quantizedStartStep: 2, quantizedEndStep: 4, program: 0 },
//     { pitch: 73, quantizedStartStep: 4, quantizedEndStep: 6, program: 0 },
//     { pitch: 74, quantizedStartStep: 6, quantizedEndStep: 8, program: 0 },
//     { pitch: 76, quantizedStartStep: 8, quantizedEndStep: 10, program: 0 },
//     { pitch: 81, quantizedStartStep: 12, quantizedEndStep: 16, program: 0 },
//     { pitch: 78, quantizedStartStep: 16, quantizedEndStep: 20, program: 0 },
//     { pitch: 81, quantizedStartStep: 20, quantizedEndStep: 24, program: 0 },
//     { pitch: 76, quantizedStartStep: 24, quantizedEndStep: 32, program: 0 },
//   ],
//   quantizationInfo: { stepsPerQuarter: 4 },
//   totalQuantizedSteps: 32,
// };

// const noteSeq = [{ pitch: 72, quantizedStartStep: 0, quantizedEndStep: 2 }];

// const seedSeq = {
//   totalQuantizedStesp: 4,
//   totalTime: 5,
//   quantizationInfo: { stepsPerQuarter: 1 },
//   notes: noteSeq,
// };

// async function generateMelody(quantizedSequence) {
//   try {
//     const melodyrnn = await loadMelodyRnn();
//     let resultSeq = await melodyrnn.continueSequence(mel_teapot, 10);

//   } catch (error) {
//     console.log("error", error);
//   }
// }

// const Midi = {
//   midiToNoteName: (x) => x,
// };

const generateBaseline = (rootNoteMidi, length) => {
  const roots = ["C4", "D4", "E4", "F4", "G4", "A4"];
  return Array(length).map(() => randomChoiceList(roots));
};

const generateMelody = (
  rootNoteMidi,
  mode = "major",
  length = 4,
  bpm = 120
) => {
  let scaleDegreeMidiDiffMap = {
    1: 0,
    2: 2,
    3: 4,
    4: 5,
    5: 7,
    6: 9,
    7: 11,
    8: 12,
  };
  if (mode == "minor") {
    scaleDegreeMidiDiffMap = {
      1: 0,
      2: 2,
      3: 3,
      4: 5,
      5: 7,
      6: 8,
      7: 10,
      8: 12,
    };
  }
  let degrees = randomChoiceList(Object.keys(scaleDegreeMidiDiffMap), length);
  let melodyNotes = degrees.map((x) =>
    Midi.midiToNoteName(rootNoteMidi + scaleDegreeMidiDiffMap[x])
  );
  let melodyMidi = [];
  for (let i = 0; i < degrees.length; i++) {
    melodyMidi.push({
      pitchMidi: rootNoteMidi + scaleDegreeMidiDiffMap[degrees[i]],
      startTimeSeconds: (i * 60) / bpm,
      durationSeconds: 60 / bpm,
      amplitude: 1,
    });
  }
  return { melodyNotes, melodyMidi };
};

const getChordNotesFromMidi = (rootMidi, quality) => {
  if (quality == "major") {
    return [0, 4, 7].map((x) => Midi.midiToNoteName(rootMidi + x));
  }
  if (quality == "minor") {
    return [0, 3, 7].map((x) => Midi.midiToNoteName(rootMidi + x));
  }
};

const getChordMidiFromMidi = (rootMidi, quality) => {
  if (quality == "major") {
    return [0, 4, 7].map((x) => rootMidi + x);
  }
  if (quality == "minor") {
    return [0, 3, 7].map((x) => rootMidi + x);
  }
};

const generateChordProgression = (
  rootNoteMidi,
  mode = "major",
  length = 4,
  bpm = 120
) => {
  let scaleDegreeQuality = {};
  let scaleDegreeMidiDiffMap = {
    1: 0,
    2: 2,
    3: 4,
    4: 5,
    5: 7,
    6: 9,
    // 7: 11,
    // 8: 12
  };

  scaleDegreeQuality = {
    1: "major",
    2: "minor",
    3: "minor",
    4: "major",
    5: "major",
    6: "minor",
  };
  if (mode == "minor") {
    scaleDegreeMidiDiffMap = {
      1: 0,
      // 2: 2,
      3: 3,
      4: 5,
      5: 7,
      6: 8,
      7: 10,
      // 7: 11,
      // 8: 12
    };
    scaleDegreeQuality = {
      1: "minor",
      // 2: "diminished",
      3: "major",
      4: "minor",
      5: "minor",
      6: "major",
      7: "major",
    };
  }
  const rootNotes = randomChoiceList(
    Object.keys(scaleDegreeMidiDiffMap),
    length
  );

  const chordsMidi = [];
  const chords = rootNotes.map((x) =>
    getChordNotesFromMidi(
      rootNoteMidi + scaleDegreeMidiDiffMap[x],
      scaleDegreeQuality[x]
    )
  );

  for (let i = 0; i < rootNotes.length; i++) {
    let chordNotes = getChordMidiFromMidi(
      rootNoteMidi + scaleDegreeMidiDiffMap[rootNotes[i]],
      scaleDegreeQuality[rootNotes[i]]
    );
    for (let note of chordNotes) {
      chordsMidi.push({
        pitchMidi: note,
        startTimeSeconds: (i * 60) / bpm,
        durationSeconds: 60 / bpm,
        amplitude: 1,
      });
    }
  }

  return { chords, chordsMidi };
};

export { generateMelody, generateChordProgression };
