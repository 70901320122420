import supabase from "./supabase";
import { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";

const EmailCollectionForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const { email, error } = await supabase.auth.signUp({
    //   email: event.target.email.value,
    // });
    // if (error) {
    //   alert(error.message);
    // } else {
    //   alert("Check your email for the confirmation link");
    // }
    const { data, error } = await supabase.from("emails").insert({
      email: email,
    });
    if (error) {
      console.log("error", error);
      setMessage(
        "Something went wrong. Please contact rosminebuilds@gmail.com"
      );
    } else {
      setMessage(
        "Thanks for signing up! Don't expect an email immediately because I haven't set up the email automations yet 😅"
      );
    }
  };

  return (
    <Container className="border p-2" style={{ width: 400 }}>
      <Row>
        <Form
          onSubmit={handleSubmit}
          onChange={(event) => {
            console.log("email", email);
            setEmail(event.target.value);
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Get notifications about new features</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        {message != "" ? <p>{message}</p> : <></>}
      </Row>
    </Container>
  );
};

export default EmailCollectionForm;
