import * as Tone from "tone";
import { randomChoiceList } from "./utils";
import Midi from "./Midi";

// const C3Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/C3.mp3`);
// const G3Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/G3.mp3`);
// const C4Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/C4.mp3`);
// const G4Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/G4.mp3`);
// const C5Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/C5.mp3`);
// const G5Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/G5.mp3`);
// const C6Url = new Tone.Buffer(`${process.env.PUBLIC_URL}/C5.mp3`);

const C3Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/C3.mp3`
);
const G3Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/G3.mp3`
);
const C4Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/C4.mp3`
);
const G4Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/G4.mp3`
);
const C5Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/C5.mp3`
);
const G5Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/G5.mp3`
);
const C6Url = new Tone.Buffer(
  `https://eartrainerpublic.s3.us-west-2.amazonaws.com/C6.mp3`
);

const metronome = new Tone.MembraneSynth().toDestination();

// Set the metronome parameters
metronome.oscillator.type = "sine";
metronome.envelope.decay = 0.01;
metronome.volume.value = 0;

// Set the tempo (beats per minute) of the metronome
Tone.Transport.bpm.value = 240;

const createSampler = (bpm: number): Tone.Sampler => {
  Tone.Transport.bpm.value = bpm;
  const sampler = new Tone.Sampler({
    // Todo play with release
    urls: {
      C3: C3Url,
      G3: G3Url,
      C4: C4Url,
      G4: G4Url,
      C5: C5Url,
      G5: G5Url,
      C6: C6Url,
    },
    release: 3,
  }).toDestination();
  sampler.volume.value = 10;
  return sampler;
};

const createSynth = (): Tone.Synth => {
  Tone.Transport.bpm.value = 60;
  const synth = new Tone.Synth().toDestination();
  return synth;
};

const playScale = (sampler: Tone.Sampler): void => {
  const scale = ["C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5"];
  const now = Tone.now();
  for (let i = 0; i < scale.length; i++) {
    sampler.triggerAttackRelease(scale[i], "8n", now + i * 0.5);
  }
};

// Create a function to play the metronome tick
function playMetronomeTick(time: number) {
  metronome.triggerAttackRelease("C2", "16n", time);
}

const playMetronomeIntro = (
  metronomeIntro: number,
  bpm: number,
  now: number,
  offset: number
): void => {
  for (let i = 0; i < metronomeIntro; i++) {
    playMetronomeTick(now + (i * 60) / bpm + offset);
  }
};

const playChordProgression = (
  sampler: Tone.Sampler,
  chords: string[],
  bpm: number = 60,
  offset: number = 0,
  metronomeIntro = 4
): void => {
  sampler.context.resume();
  const now = Tone.now();

  if (metronomeIntro > 0) {
    playMetronomeIntro(metronomeIntro, bpm, now, offset);
  }

  for (let i = 0; i < chords.length; i++) {
    for (let note of chords[i]) {
      sampler.triggerAttackRelease(
        note,
        "4n",
        now + ((i + metronomeIntro) * 60) / bpm + offset
      );
    }
  }
};

const playSimpleMelody = (
  sampler: Tone.Sampler,
  melodyMidi: any[],
  bpm: number = 60,
  offset: number = 0,
  metronomeIntro = 4
): void => {
  // sampler.context.resume();
  const now = Tone.now();

  if (metronomeIntro > 0) {
    playMetronomeIntro(metronomeIntro, bpm, now, offset);
  }

  for (let i = 0; i < melodyMidi.length; i++) {
    sampler.triggerAttackRelease(
      Midi.midiToNoteName(melodyMidi[i].pitchMidi),
      "4n",
      now + melodyMidi[i].startTimeSeconds + offset
    );
  }
};

export {
  createSampler,
  createSynth,
  playScale,
  randomChoiceList,
  playSimpleMelody,
  playMetronomeIntro,
  playChordProgression,
};
