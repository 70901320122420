import "./App.css";
import React, { Component } from "react";

import audioRecorder from "./AudioRecorder";
import {
  audioToMidi,
  postprocessML,
  VEX_FORMAT_NOTE_LENGTH_MAP,
  checkWebglSupport,
  // hasMicrophoneAccess,
  getMicrophoneAccess,
  convertVirtualPianoNotesToMidi,
  convertMidiToVex,
  getMissingNotesMidi,
  convertMidiToVexBothClef,
  filterMidiToFirstNotes,
} from "./doStuff";
import EmailCollectionForm from "./emailCollectionForm";

import drawNotes from "./drawNotes";

import Vex, { Voice } from "vexflow";

import {
  createSampler,
  createSynth,
  playScale,
  randomChoiceList,
  playSimpleMelody,
  playMetronomeIntro,
  playChordProgression,
} from "./Sampler";
import * as Tone from "tone";
import {
  printMidi,
  filenameToAudioBlob,
  noteNameToVexflow,
  keySignatureOffset,
  midiTestCase1,
  midiTestCase2,
  getBeatCount,
  filterMidiToMeasureRange,
  splitMidiTrebleBass,
  convertKeyToBaseNote,
  fetchMidiFromS3,
  convertMidFileToMidi,
  createMidiScale,
} from "./utils";
import { generateMelody, generateChordProgression } from "./generateMelody";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import KeyPicker from "./keyPicker";
import LegalDisclaimer from "./LegalDisclaimer";
import Midi from "./Midi";
import Instructions from "./instructions";
import "./styles.css";
import { Piano, KeyboardShortcuts } from "./react-piano/src";
import UpcomingNotes from "./upcomingNotes";
import MusicVisualizer, { getRightNoteRange } from "./MusicVisualizer";
import SongLibrary, {
  blackNotes,
  filterSongToGetExercise,
  pokemonMidi,
} from "./SongLibrary";
import DifficultySettings from "./DifficultySettings";
const { Stave, StaveNote, Beam, Formatter, Renderer } = Vex;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class App extends Component {
  constructor(props) {
    super(props);
    // this.server = process.env.PUBLIC_URL + "/";
    this.server = "https://eartrainerpublic.s3.us-west-2.amazonaws.com/";
    this.scale_test_case = new Audio(this.server + "scale_test_case.mp3");
    this.chord_test_case = new Audio(this.server + "chord_test_case.mp3");
    // let filename = 'https://piano-mp3.s3.us-east-2.amazonaws.com/chord-test-case_22050.mp3'
    this.modelname = this.server + "model.json";
    this.metronomeIntro = 4;
    this.beatsPerMeasure = 4;
    this.mergeBuffer = 0.0001; // todo this should be calculated based off of bpm and min note length
    this.minAmplitude = 0;
    this.isTesting = false;
    this.smallestNote = "16n";
    // const bpm = 60;

    this.keyboardShortcuts = KeyboardShortcuts.create({
      firstNote: 60,
      lastNote: 72,
      keyboardConfig: KeyboardShortcuts.NUMBERS,
    });
    this.virtualPianoNotes = [];
    this.validPitchMidi = new Set([59, 60, 62, 64, 65, 67, 69, 71, 72]);

    this.currentDifficulty = 0;
    this.songListUrl =
      "https://eartrainerpublic.s3.us-west-2.amazonaws.com/midi/songlist.txt";
    this.state = {
      midiElement: null,
      mode: "major",
      questionType: "melody",
      key: "C",
      quality: "major",
      keyMidi: 60,
      questionLength: 4,
      questionLengthMeasures: 2,
      correctNotesMidi: [],
      // correctNotesMidi: filterMidiToMeasureRange(pokemonMidi, 0, 2, 120, 4),
      // correctNotesMidi: generateMelody(60, "major", 4, 120).melodyMidi,
      // correctNotesMidi: blackNotes,
      displayCorrect: false,
      displayFailed: false,
      bpm: 120,
      keyboardEnabled: true,
      librarySong: "Pachelbel_Canon_in_D", //null,
      librarySongMidi: null,
      librarySongCurrentSection: 0,
      availableSongs: [],
      noteFilterMode: "fullSong",
    };
    Tone.Transport.bpm.value = this.state.bpm;
    this.sampler = createSampler(this.state.bpm);
    this.didDraw = false;
    this.librarySongOriginalBPM = 0;
  }

  componentDidMount = async () => {
    // this is just to quickly test drawing notes
    if (!this.didDraw) {
      // await this.setLibraySongAsExercise();
      // let toDisplay = convertMidiToVexBothClef(
      //   this.state.correctNotesMidi,
      //   this.state.bpm,
      //   this.smallestNote,
      //   this.state.key
      // );
      // drawNotes(
      //   toDisplay.treble,
      //   toDisplay.bass,
      //   "correctNotes",
      //   this.beatsPerMeasure,
      //   convertKeyToBaseNote(this.state.key, this.state.quality)
      // );
      // drawNotes(
      //   toDisplay.treble,
      //   toDisplay.bass,
      //   "playedNotes",
      //   this.beatsPerMeasure,
      //   convertKeyToBaseNote(this.state.key, this.state.quality)
      // );

      // this.loadModel();
      this.didDraw = true;
    }

    if (this.state.availableSongs.length === 0) {
      fetch(this.songListUrl)
        .then((response) => response.text())
        .then((text) => {
          let songlist = text
            .split("\n")
            .filter((x) => x.length > 0)
            .map((x) => x.replace(/_/g, " "));
          this.setState({ availableSongs: songlist });
        })
        .catch((error) => {
          console.log("error fetching song list", error);
          return [];
        });
    }

    if (!checkWebglSupport()) {
      alert(
        "WebGL not supported. Please contact rosminebuilds@gmail.com for help."
      );
    }
  };

  updateQuestionLengthMeasures = (newLength) => {
    if (this.state.questionType === "songlibrary") {
      const currentMeasure =
        this.state.librarySongCurrentSection *
        this.state.questionLengthMeasures;
      const newCurrentSection = Math.floor(currentMeasure / newLength);
      this.setState({
        librarySongCurrentSection: newCurrentSection,
        questionLengthMeasures: newLength,
      });
    } else {
      this.setState({ questionLengthMeasures: newLength });
    }
  };

  setLibraySongAsExercise = async () => {
    const startMeasure =
      this.state.librarySongCurrentSection * this.state.questionLengthMeasures;
    const endMeasure = startMeasure + this.state.questionLengthMeasures;
    let mid = await fetchMidiFromS3(this.state.librarySong);
    let { midi, bpm, beatsPerMeasure, key } = convertMidFileToMidi(mid);

    this.librarySongOriginalBPM = bpm;
    const filteredMidi = filterMidiToMeasureRange(
      midi,
      startMeasure,
      endMeasure,
      bpm,
      beatsPerMeasure,
      this.state.noteFilterMode,
      this.librarySongOriginalBPM
    );
    this.setState({
      correctNotesMidi: filteredMidi,
      librarySongMidi: midi,
      bpm: bpm,
      key: key,
      keyMidi: Midi.noteNameToMidi(key + "4"),
    });
    this.beatsPerMeasure = beatsPerMeasure;
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.librarySong !== prevState.librarySong) {
      this.clearSheetMusic();
      this.setLibraySongAsExercise();
      this.setState({ librarySongCurrentSection: 0 });
    }
    if (
      this.state.noteFilterMode !== prevState.noteFilterMode ||
      this.state.questionLengthMeasures !== prevState.questionLengthMeasures ||
      this.state.bpm !== prevState.bpm
    ) {
      const filteredMidi = filterMidiToMeasureRange(
        this.state.librarySongMidi,
        this.state.librarySongCurrentSection *
          this.state.questionLengthMeasures,
        (this.state.librarySongCurrentSection + 1) *
          this.state.questionLengthMeasures,
        this.state.bpm,
        this.beatsPerMeasure,
        this.state.noteFilterMode,
        this.librarySongOriginalBPM
      );
      this.setState({ correctNotesMidi: filteredMidi });
    }
    if (this.state.bpm !== prevState.bpm) {
      Tone.Transport.bpm.value = this.state.bpm;
      this.sampler = createSampler(this.state.bpm);
    }
  };

  virtualPianoToNotes = () => {
    const midi = convertVirtualPianoNotesToMidi(
      this.virtualPianoNotes,
      Tone.now()
    );
    const { notes, missingNotesMidi } = postprocessML(
      midi,
      this.state.bpm,
      this.validPitchMidi,
      this.minAmplitude,
      this.beatsPerMeasure,
      this.mergeBuffer,
      this.state.correctNotesMidi,
      this.state.key
    );
    return { notes, midi, missingNotesMidi };
  };

  audioToNotes = async (
    audioBlob,
    bpm,
    onsetThresh = 0.5,
    frameThresh = 0.4,
    minNoteLen = 60 / bpm
  ) => {
    let midi = await audioToMidi(
      audioBlob,
      this.modelname,
      onsetThresh,
      frameThresh,
      minNoteLen
    );
    midi.sort((a, b) => a.startTimeSeconds - b.startTimeSeconds);

    const { notes, missingNotesMidi } = postprocessML(
      midi,
      bpm,
      this.validPitchMidi,
      this.minAmplitude,
      this.beatsPerMeasure,
      this.mergeBuffer,
      this.state.correctNotesMidi,
      this.state.key
    );

    // this.setState({ midiElement: saveMidi(midi, "midi.mid") });
    return { notes, midi, missingNotesMidi };
  };

  clearSheetMusic = () => {
    for (let parentId of ["correctNotes", "playedNotes"]) {
      let parentElement = document.getElementById(parentId);
      while (parentElement.firstChild) {
        parentElement.removeChild(parentElement.firstChild);
      }
      // let divId = parentDiv + "sheetMusic";
      // const oldDiv = document.getElementById(divId);
      // if (oldDiv != null) {
      //   oldDiv.remove();
      // }
    }
    this.setState({ displayCorrect: false, displayFailed: false });
  };

  loadModel = async () => {
    const testCaseBlob = await filenameToAudioBlob(this.scale_test_case.src);
    let midi = audioToMidi(testCaseBlob, this.modelname);
  };

  playSample = () => {
    const sampler = createSampler(this.state.bpm);
    playScale(sampler);
  };

  pleaseSelectSong = () => {
    if (this.state.correctNotesMidi.length === 0) {
      alert("Please select a song first");
      return false;
    }
    return true;
  };

  playQuestion = async (newQuestion = true) => {
    this.reset();
    this.setState({
      displayCorrect: false,
      displayFailed: false,
    });
    if (this.state.questionType === "melody") {
      await this.playMelodyQuestion(newQuestion);
    } else if (this.state.questionType === "chordprogression") {
      await this.playChordProgressionQuestion(newQuestion);
    } else if (this.state.questionType === "songlibrary") {
      this.clearSheetMusic();
      this.pleaseSelectSong();
      playSimpleMelody(
        this.sampler,
        this.state.correctNotesMidi,
        this.state.bpm,
        0,
        0
      );
    }
  };

  playMelodyQuestion = async (newQuestion = false) => {
    this.clearSheetMusic();
    let melodyNotes, melodyMidi;
    if (newQuestion) {
      // TODO change generate melody to only output midi
      let melody = generateMelody(
        this.state.keyMidi,
        this.state.mode,
        this.state.questionLength,
        this.state.bpm
      );
      // melody = createMidiScale(this.state.keyMidi, this.state.bpm);
      melodyMidi = melody.melodyMidi;
      this.setState({ correctNotes: melody.melodyNotes.map((x) => [x]) });
      this.setState({ correctNotesMidi: melodyMidi });
    } else {
      this.needToPlayQuestionFirst();
      melodyMidi = this.state.correctNotesMidi;
    }
    playSimpleMelody(this.sampler, melodyMidi, this.state.bpm, 0, 0);
  };

  playTonic = async () => {
    playSimpleMelody(
      this.sampler,
      [{ pitchMidi: this.state.keyMidi, startTimeSeconds: 0 }],
      this.state.bpm,
      0,
      0
    );
  };

  playChordProgressionQuestion = async (newQuestion = false) => {
    let correctNotesMidi;
    if (newQuestion) {
      let chords = generateChordProgression(
        this.state.keyMidi,
        this.state.questionLength
      );
      correctNotesMidi = chords.chordsMidi;
      this.setState({ correctNotesMidi: correctNotesMidi });
    } else {
      this.needToPlayQuestionFirst();
      correctNotesMidi = this.state.correctNotesMidi;
    }

    playSimpleMelody(this.sampler, correctNotesMidi, this.state.bpm, 0, 0);
  };

  // getMissingNotes = (playedNotes, correctNotes) => {
  //   let numberMissed = 0;
  //   const missingNotes = [];
  //   let missedNotesBeat = [];
  //   for (let i = 0; i < correctNotes.length; i++) {
  //     if (playedNotes.length <= i || playedNotes[i].noteType === "r") {
  //       missingNotes.push(correctNotes[i]);
  //       numberMissed += 1;
  //     } else {
  //       missedNotesBeat = [];
  //       for (let note of correctNotes[i].keys) {
  //         if (
  //           !playedNotes[i].keys.includes(note) &&
  //           playedNotes[i].noteType !== "r"
  //         ) {
  //           missedNotesBeat.push(note);
  //           numberMissed += 1;
  //         }
  //       }
  //       if (missedNotesBeat.length > 0) {
  //         missingNotes.push(
  //           new StaveNote({
  //             keys: missedNotesBeat,
  //             duration: "q",
  //           })
  //         );
  //       } else {
  //         missingNotes.push(
  //           new StaveNote({
  //             keys: ["b/4"],
  //             duration: "qr",
  //           })
  //         );
  //       }
  //     }
  //   }
  //   return { missingNotes: missingNotes, numberMissed: numberMissed };
  // };

  reset = () => {
    this.setState({ displayCorrect: false, displayFailed: false });
    this.clearSheetMusic();
    this.virtualPianoNotes = [];
  };

  setWarning = () => {
    const div = document.getElementById("playedNotes");
    div.innerHTML =
      "<p>Something went wrong. Please try another browser, or reach out to rosminebuilds@gmail.com for help</p>";
  };

  needToPlayQuestionFirst = () => {
    if (this.state.correctNotesMidi.length === 0) {
      alert('Please Click "Play New Question" first');
      return;
    }
  };

  changeSection = (increment) => {
    this.pleaseSelectSong();
    this.clearSheetMusic();
    let newSection = this.state.librarySongCurrentSection + increment;
    newSection = Math.max(0, newSection);
    // TODO: need to get bpm from the midi song. Right now only works if bpm is 120
    let maxSections =
      Math.ceil(
        ((Math.max(
          ...this.state.librarySongMidi.map(
            (x) => x.startTimeSeconds + x.durationSeconds
          )
        ) /
          60) *
          this.state.bpm) /
          this.beatsPerMeasure /
          this.state.questionLengthMeasures
      ) - 1;
    if (newSection > maxSections) {
      alert("No more sections, you finished the song!");
      return;
    }
    // debugger;
    const filteredMidi = filterMidiToMeasureRange(
      this.state.librarySongMidi,
      newSection * this.state.questionLengthMeasures,
      (newSection + 1) * this.state.questionLengthMeasures,
      this.state.bpm,
      this.beatsPerMeasure,
      this.state.noteFilterMode,
      this.librarySongOriginalBPM
    );

    this.setState({
      correctNotesMidi: filteredMidi,
      librarySongCurrentSection: newSection,
    });
  };

  showAnswerSheetMusic = () => {
    this.clearSheetMusic();
    let toDisplay = convertMidiToVexBothClef(
      this.state.correctNotesMidi,
      this.state.bpm,
      this.smallestNote,
      this.state.key
    );
    drawNotes(
      toDisplay.treble,
      toDisplay.bass,
      "correctNotes",
      this.beatsPerMeasure,
      convertKeyToBaseNote(this.state.key, this.state.quality)
    );
  };

  assessAnswer = async (test_case = null) => {
    if (this.state.correctNotesMidi.length === 0) {
      alert('Please Click "Play New Question" first');
      return;
    }
    this.reset();
    this.isTesting = true;
    let notes, canGo, letsGo, playedNotesMidi, results, missingNotesMidi;
    // todo replace this with midi
    let toDisplay = convertMidiToVexBothClef(
      this.state.correctNotesMidi,
      this.state.bpm,
      this.smallestNote,
      this.state.key
    );

    // drawNotes(toDisplay.treble, toDisplay.bass, "correctNotes", this.beatsPerMeasure, this.state.key);
    if (test_case == null) {
      const offset = 0.5;
      playMetronomeIntro(
        this.metronomeIntro,
        this.state.bpm,
        Tone.now(),
        offset
      );
      await sleep((60 / this.state.bpm) * (this.metronomeIntro + 0.1) * 1000);
      canGo = await audioRecorder
        .start()
        .then(() => true)
        .catch((err) => {
          console.log("err", err);
          return false;
        });

      if (canGo) {
        await sleep(
          (60 / this.state.bpm) *
            (getBeatCount(this.state.correctNotesMidi, this.state.bpm) + 3) *
            1000
        );
        letsGo = await audioRecorder
          .stop()
          .then(() => true)
          .catch((err) => {
            console.log("err", err);
            return false;
          });
        this.isTesting = false;
        if (this.virtualPianoNotes.length == 0) {
          results = await this.audioToNotes(
            audioRecorder.audioBlob,
            this.state.bpm
          ).catch((err) => {
            console.log("err", err);
            return false;
          });
        } else {
          results = this.virtualPianoToNotes();
        }
      }
    } else {
      const testCaseBlob = await filenameToAudioBlob(test_case.src);
      results = await this.audioToNotes(testCaseBlob, this.state.bpm);
    }
    notes = results.notes;
    playedNotesMidi = results.midi;
    missingNotesMidi = results.missingNotesMidi;
    let correctNoteWidthParams = drawNotes(
      toDisplay.treble,
      toDisplay.bass,
      "correctNotes",
      this.beatsPerMeasure,
      convertKeyToBaseNote(this.state.key, this.state.quality)
    );
    if (canGo && letsGo && notes) {
      if (missingNotesMidi.length === 0) {
        this.setState({ displayCorrect: true });
      } else {
        let missingNotes = convertMidiToVexBothClef(
          missingNotesMidi,
          this.state.bpm,
          this.smallestNote,
          this.state.key,
          this.beatsPerMeasure
        );
        this.setState({ displayCorrect: false });
        this.setState({ displayFailed: true });
        drawNotes(
          missingNotes.treble,
          missingNotes.bass,
          "playedNotes",
          this.beatsPerMeasure,
          convertKeyToBaseNote(this.state.key, this.state.quality),
          correctNoteWidthParams
        );
      }
    } else {
      this.setWarning();
    }
  };

  debug = () => {
    const synth = new Tone.Synth().toDestination();
    synth.triggerAttackRelease("C4", "8n");
  };

  handleKeyChange = (key) => {
    this.setState({ key: key, keyMidi: Midi.noteNameToMidi(key + "4") });
  };

  handleModeChange = (mode) => {
    this.setState({ mode: mode });
  };

  handleQuestionTypeChange = (questionType) => {
    this.setState({ questionType: questionType, correctNotesMidi: [] });
    this.clearSheetMusic();
  };

  handleVirtualPianoKeyDown = (midiNote, timestamp) => {
    if (this.isTesting) {
      this.virtualPianoNotes.push({
        pitchMidi: midiNote,
        timestamp: timestamp,
        action: "d",
      });
    }
  };

  handleVirtualPianoKeyUp = (midiNote, timestamp) => {
    if (this.isTesting) {
      this.virtualPianoNotes.push({
        pitchMidi: midiNote,
        timestamp: timestamp,
        action: "u",
      });
    }
  };

  getDifficultyScore = (midi) => {
    if (this.state.questionType === "songlibrary") {
      const totalLength = Math.max(
        ...midi.map((note) => note.startTimeSeconds + note.durationSeconds)
      );
      return (midi.length / totalLength).toFixed(1);
    } else if (this.state.questionType === "melody") {
      return (this.state.bpm / 60).toFixed(1);
    } else if (this.state.questionType === "chordprogression") {
      return ((3 * this.state.bpm) / 60).toFixed(1);
    }
  };

  setKeyboardEnabled = (enabled) => {
    this.setState({ keyboardEnabled: enabled });
  };

  updateBPM = (bpm) => {
    this.setState({ bpm: bpm });
  };

  setNoteFilterMode = (noteFilterMode) => {
    this.setState({ noteFilterMode: noteFilterMode });
  };

  showFirstNotes = () => {
    this.clearSheetMusic();
    if (!this.pleaseSelectSong()) {
      return;
    }
    let toDisplay = convertMidiToVexBothClef(
      filterMidiToFirstNotes(this.state.correctNotesMidi),
      this.state.bpm,
      this.smallestNote,
      this.state.key
    );
    drawNotes(
      toDisplay.treble,
      toDisplay.bass,
      "playedNotes",
      this.beatsPerMeasure,
      convertKeyToBaseNote(this.state.key, this.state.quality)
    );
  };

  render = () => {
    const difficulty = this.getDifficultyScore(this.state.correctNotesMidi);
    return (
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          <h1>All By Ear: Learn to play any song you hear! </h1>
        </Row>
        <p>
          Warning: This looks very bad on phones right now. Please try on a
          bigger screen.
        </p>
        <p>
          This website is currently ugly and missing features. Please check back
          in the future.
          {/*Add your email
          below to get updates when there are improvements (don't worry I won't
    spam you){" "}*/}
        </p>
        {/* <EmailCollectionForm /> */}
        <Instructions />
        <Row>
          <Col className="col-lg-6">
            <Row>
              <Col className="">
                <h1 className="text-end ">Test Mode:</h1>
              </Col>
              <Col className="d-flex align-items-center">
                <ButtonGroup className="align-items-center">
                  <ToggleButton
                    className="rounded"
                    type="radio"
                    checked={this.state.questionType === "melody"}
                    value="melody"
                    onClick={(e) => this.handleQuestionTypeChange("melody")}
                  >
                    Melody
                  </ToggleButton>
                  <ToggleButton
                    className="m-2 rounded"
                    type="radio"
                    checked={this.state.questionType === "chordprogression"}
                    value="chordprogression"
                    onClick={(e) => {
                      this.handleQuestionTypeChange("chordprogression");
                    }}
                  >
                    Chords
                  </ToggleButton>
                  <ToggleButton
                    className="rounded"
                    style={{ width: 150 }}
                    type="radio"
                    checked={this.state.questionType === "songlibrary"}
                    value="songlibrary"
                    onClick={(e) => {
                      this.handleQuestionTypeChange("songlibrary");
                    }}
                  >
                    Song Library
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <DifficultySettings
                updateQuestionLengthMeasures={this.updateQuestionLengthMeasures}
                questionLengthMeasures={this.state.questionLengthMeasures}
                updateBPM={this.updateBPM}
                bpm={this.state.bpm}
                setKeyboardEnabled={this.setKeyboardEnabled}
                musicKey={this.state.key}
                mode={this.state.mode}
                handleModeChange={this.handleModeChange}
                setNoteFilterMode={this.setNoteFilterMode}
                noteFilterMode={this.state.noteFilterMode}
                handleKeyChange={this.handleKeyChange}
              />
              {difficulty > 0 ? (
                <Col className="m-0 d-flex justify-content-center align-items-center">
                  <p className="m-0 ">Difficulty: {difficulty} notes/second</p>
                </Col>
              ) : null}
            </Row>
            {this.state.questionType == "songlibrary" ? (
              <SongLibrary
                currentSection={this.state.librarySongCurrentSection}
                setSong={(song) => {
                  this.setState({ librarySong: song });
                }}
                nextSection={() => this.changeSection(1)}
                prevSection={() => this.changeSection(-1)}
                availableSongs={this.state.availableSongs}
              />
            ) : null}
            {this.state.questionType !== "songlibrary" ? (
              <Row className="d-flex flex-row justify-content-center">
                <Button
                  className="m-2"
                  style={{ width: 200 }}
                  onClick={() => this.playQuestion(true)}
                >
                  Play New Question
                </Button>
                <Button
                  className="m-2"
                  style={{ width: 200 }}
                  onClick={() => this.playQuestion(false)}
                >
                  Play Question Again
                </Button>
              </Row>
            ) : null}
            {this.state.questionType === "songlibrary" ? (
              <Row>
                <Button
                  className="m-3"
                  style={{ width: 200 }}
                  onClick={() => this.playQuestion(false)}
                >
                  Play Current Section
                </Button>
              </Row>
            ) : null}
            <Row
              className="justify-content-center align-items-start"
              // style={{ height: 350 }}
            >
              <Row id="correctNotes"></Row>
              <Row id="playedNotes"></Row>
              {this.state.displayCorrect ? (
                <Row>
                  <h3 className="flex-row text-center">Correct!</h3>
                </Row>
              ) : null}
            </Row>
            <Row className="d-flex flex-row justify-content-center">
              <Button
                className="m-3"
                style={{ width: 200 }}
                onClick={() => this.assessAnswer()}
              >
                Test Me
              </Button>
              <Button
                className="m-3"
                style={{ width: 200 }}
                onClick={() => this.playTonic()}
              >
                Play Tonic
              </Button>
            </Row>
          </Col>
          <Col className="col-lg-6 d-flex align-items-center justify-content-center">
            <MusicVisualizer
              sampler={this.sampler}
              width={550}
              height={400}
              correctNotesMidi={this.state.correctNotesMidi}
              bpm={this.state.bpm}
              keyboardShortcuts={this.keyboardShortcuts}
              noteRange={getRightNoteRange(this.state.correctNotesMidi)}
              handleVirtualPianoKeyDown={this.handleVirtualPianoKeyDown}
              handleVirtualPianoKeyUp={this.handleVirtualPianoKeyUp}
              keyboardEnabled={this.state.keyboardEnabled}
              showAnswerSheetMusic={this.showAnswerSheetMusic}
              showFirstNotes={this.showFirstNotes}
            />
          </Col>
        </Row>

        <LegalDisclaimer />
      </Container>
    );
  };
}

export default App;
