import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { useState, useEffect } from "react";

const MAX_BPM = 550;

const DifficultySettings = (props) => {
  const [show, setShow] = useState(false);
  const [newBPM, setNewBPM] = useState(props.bpm);
  const [musicKey, setMusicKey] = useState(props.musicKey);
  const [mode, setMode] = useState(props.mode);
  const [bpmMessage, setBpmMessage] = useState("");

  useEffect(() => {
    setNewBPM(props.bpm);
  }, [props.bpm]);

  useEffect(() => {
    setMusicKey(props.musicKey);
    setMode(props.mode);
  }, [props.musicKey, props.mode]);

  const handleKeyChange = (event) => {
    setMusicKey(event.target.value);
    props.handleKeyChange(event.target.value);
  };

  const handleModeChange = (event) => {
    setMode(event.target.value);
    props.handleModeChange(event.target.value);
  };

  const handleBpmChange = (bpm) => {
    if (bpm < 50 || bpm > MAX_BPM) {
      setBpmMessage(`BPM must be between 50 and ${MAX_BPM}`);
      setNewBPM(bpm);
    } else {
      setBpmMessage("");
      setNewBPM(bpm);
    }
  };

  const handleClose = () => {
    setShow(false);
    props.setKeyboardEnabled(true);
    if (newBPM == "") {
      setNewBPM(120);
      props.updateBPM(120);
    } else {
      props.updateBPM(Math.max(Math.min(newBPM, MAX_BPM), 50));
    }
  };
  const handleShow = () => {
    setShow(true);
    props.setKeyboardEnabled(false);
  };

  return (
    <>
      <Button className="w-50" variant="primary" onClick={handleShow}>
        Difficulty Settings
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Difficulty Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-4">
              <Col>Number of Measures in Question:</Col>
              <Col>
                <ButtonGroup>
                  <ToggleButton
                    type="radio"
                    checked={props.questionLengthMeasures === 1}
                    onClick={() => props.updateQuestionLengthMeasures(1)}
                    variant="primary"
                  >
                    1
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    checked={props.questionLengthMeasures === 2}
                    onClick={() => props.updateQuestionLengthMeasures(2)}
                    variant="primary"
                  >
                    2
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <p>Beats Per Minute</p>
              </Col>
              <Col>
                <Form.Group className="w-100" controlId="text-input">
                  <FormControl
                    className=" mr-0"
                    onChange={(e) => handleBpmChange(e.target.value)}
                    type="number"
                    placeholder={newBPM}
                    value={newBPM}
                  />
                  {bpmMessage ? (
                    <p style={{ color: "red" }}>{bpmMessage}</p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="d-flex justify-content-start">
                Key (only changes melody/chord exercises, not song library yet):
              </Col>
              <Col>
                <Form.Select
                  style={{ width: "200px" }}
                  aria-label="Select Key"
                  value={musicKey}
                  onChange={(e) => handleKeyChange(e)}
                >
                  {/* <option value={props.key}>C</option> */}
                  <option value="Gb">G&#x266d;</option>
                  <option value="Db">D&#x266d;</option>
                  <option value="Ab">A&#x266d;</option>
                  <option value="Eb">E&#x266d;</option>
                  <option value="Bb">B&#x266d;</option>
                  <option value="F">F</option>
                  <option value="C">C</option>
                  <option value="G">G</option>
                  <option value="D">D</option>
                  <option value="A">A</option>
                  <option value="E">E</option>
                  <option value="B">B</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>Split Song Library Exercises Treble/Bass</Col>
              <Col>
                <ButtonGroup>
                  <ToggleButton
                    type="radio"
                    checked={props.noteFilterMode === "treble"}
                    onClick={() => props.setNoteFilterMode("treble")}
                    variant="primary"
                  >
                    Treble Only
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    checked={props.noteFilterMode === "bass"}
                    onClick={() => props.setNoteFilterMode("bass")}
                    variant="primary"
                  >
                    Bass Only
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    checked={props.noteFilterMode === "fullSong"}
                    onClick={() => props.setNoteFilterMode("fullSong")}
                    variant="primary"
                  >
                    Full Song
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  return <div></div>;
};

export default DifficultySettings;
