import React, { useEffect, useState, Component } from "react";
import Button from "react-bootstrap/Button";
import Midi from "./Midi";
import UpcomingNotes from "./upcomingNotes";
import Piano from "./react-piano/src/Piano";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Tone from "tone";

const getRightNoteRange = (midi) => {
  let lowestNote = Math.min(...midi.map((note) => note.pitchMidi));
  let highestNote = Math.max(...midi.map((note) => note.pitchMidi));
  const blackNotes = [1, 3, 6, 8, 10];
  if (blackNotes.includes(lowestNote % 12)) {
    lowestNote -= 1;
  }
  if (blackNotes.includes(highestNote % 12)) {
    highestNote += 1;
  }
  return { first: Math.min(lowestNote, 60), last: Math.max(highestNote, 72) };
};

class MusicVisualizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correctNotesMidi: props.correctNotesMidi,
      bpm: props.bpm,
      activeNotes: [],
      noteRange: props.noteRange,
      keyboardEnabled: props.keyboardEnabled,
    };
    //TODO: Is parallel safe actually required, or was that not the real cause of the bug?
    this.activeNotesParallelSafe = [];
    this.buffer = 10;
    this.beatDelay = 2;
    this.width = props.width;
    this.height = props.height;
    this.sampler = props.sampler;
    this.handleVirtualPianoKeyDown = props.handleVirtualPianoKeyDown;
    this.handleVirtualPianoKeyUp = props.handleVirtualPianoKeyUp;
    this.keyboardShortcuts = props.keyboardShortcuts;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.correctNotesMidi !== prevProps.correctNotesMidi) {
      this.setState({ correctNotesMidi: this.props.correctNotesMidi });
    }
    if (this.props.bpm !== prevProps.bpm) {
      this.setState({ bpm: this.props.bpm });
    }
    if (this.props.noteRange !== prevProps.noteRange) {
      this.setState({ noteRange: this.props.noteRange });
    }
    if (this.props.keyboardEnabled !== prevProps.keyboardEnabled) {
      this.setState({ keyboardEnabled: this.props.keyboardEnabled });
    }
  };

  playNote = (midiNumber) => {
    this.sampler.triggerAttack(Midi.midiToNoteName(midiNumber));
    this.handleVirtualPianoKeyDown(midiNumber, Tone.now());
  };
  stopNote = (midiNumber) => {
    this.sampler.triggerRelease(Midi.midiToNoteName(midiNumber));
    this.handleVirtualPianoKeyUp(midiNumber, Tone.now());
  };

  addActiveNotes = (notes) => {
    let newActiveNotes = [
      ...new Set([...this.activeNotesParallelSafe, ...notes]),
    ];
    this.activeNotesParallelSafe = newActiveNotes;
    this.setState({ activeNotes: this.activeNotesParallelSafe });
  };

  removeActiveNotes = (notes) => {
    const newActiveNotes = this.state.activeNotes.filter(
      (activeNote) => !notes.includes(activeNote)
    );
    this.activeNotesParallelSafe = newActiveNotes;
    this.setState({ activeNotes: this.activeNotesParallelSafe });
  };

  render = () => {
    return (
      <Container>
        <UpcomingNotes
          width={this.width}
          height={this.height}
          correctNotesMidi={this.state.correctNotesMidi}
          bpm={this.state.bpm}
          playQuestion={() => this.playQuestion(false)}
          playNote={(midiNumber) => this.playNote(midiNumber)}
          stopNote={(midiNumber) => this.stopNote(midiNumber)}
          beatDelay={this.beatDelay}
          addActiveNotes={(note) => this.addActiveNotes(note)}
          removeActiveNotes={(note) => this.removeActiveNotes(note)}
          noteRange={this.state.noteRange}
          showAnswerSheetMusic={this.props.showAnswerSheetMusic}
          showFirstNotes={this.props.showFirstNotes}
        />
        <Piano
          noteRange={this.state.noteRange}
          activeNotes={this.state.activeNotes}
          playNote={(midiNumber) => this.playNote(midiNumber)}
          stopNote={(midiNumber) => this.stopNote(midiNumber)}
          width={this.width}
          keyboardShortcuts={this.keyboardShortcuts}
          disabled={!this.state.keyboardEnabled}
        ></Piano>
      </Container>
    );
  };
}

export default MusicVisualizer;
export { getRightNoteRange };
