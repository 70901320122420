// https://ralzohairi.medium.com/audio-recording-in-javascript-96eed45b75ee
// https://raw.githubusercontent.com/ralzohairi/js-audio-recording/master/js/audio-recording.js

var audioRecorder = {
  audioBlobs: [],
  audioBlob: null,
  mediaRecorder: null,
  streamBeingCaptured: null,
  audioUrl: null,
  start: function () {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      return Promise.reject(
        new Error(
          "mediaDevices API or getUserMedia method is not supported in this browser."
        )
      );
    } else {
      return navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioRecorder.streamBeingCaptured = stream;
          audioRecorder.mediaRecorder = new MediaRecorder(stream);
          audioRecorder.audioBlobs = [];

          //add a dataavailable event listener in order to store the audio data Blobs when recording
          audioRecorder.mediaRecorder.addEventListener(
            "dataavailable",
            (event) => {
              audioRecorder.audioBlobs.push(event.data);
            }
          );
          audioRecorder.mediaRecorder.start();
        });
    }
  },

  stop: function () {
    return new Promise((resolve) => {
      let mimeType = audioRecorder.mediaRecorder.mimeType;
      audioRecorder.mediaRecorder.addEventListener("stop", () => {
        audioRecorder.audioBlob = new Blob(audioRecorder.audioBlobs, {
          type: mimeType,
        });
        audioRecorder.audioUrl = URL.createObjectURL(audioRecorder.audioBlob);

        resolve(audioRecorder.audioUrl);
      });
      audioRecorder.cancel();
    });
  },

  cancel: function () {
    audioRecorder.mediaRecorder.stop();
    audioRecorder.stopStream();
    audioRecorder.resetRecordingProperties();
  },

  stopStream: function () {
    audioRecorder.streamBeingCaptured
      .getTracks()
      .forEach((track) => track.stop());
  },

  resetRecordingProperties: function () {
    audioRecorder.mediaRecorder = null;
    audioRecorder.streamBeingCaptured = null;
  },

  playback: function () {
    const audio = new Audio(audioRecorder.audioUrl);
    audio.play();
  },
};

export default audioRecorder;
