import {
  BasicPitch,
  noteFramesToTime,
  addPitchBendsToNoteEvents,
  outputToNotesPoly,
  NoteEventTime,
} from "@spotify/basic-pitch";
import * as tf from "@tensorflow/tfjs";
import { StaveNote } from "vexflow";
import Midi from "./Midi";

const utils = require("./utils") as any;
// import { filenameToAudioBlob } from "./utils";
const filenameToAudioBlob = utils.filenameToAudioBlob;

function checkWebglSupport() {
  try {
    var canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl2") || canvas.getContext("experimental-webgl2"))
    );
  } catch (e) {
    return false;
  }
}

const splitMidiTrebleBass = (midi: any) => {
  const trebleMidi = midi.filter((note: any) => {
    return note.clef == "treble" || note.clef == undefined;
  });
  const bassMidi = midi.filter((note: any) => {
    return note.clef == "bass";
  });
  return [trebleMidi, bassMidi];
};

const convertVirtualPianoNotesToMidi = (notes: any[], now: number): any[] => {
  let currentlyPressedNotes: any = {}; // {pitchMidi: startTime}
  let midiNotes: any[] = [];
  for (let note of notes) {
    if (note.action == "d") {
      currentlyPressedNotes[note.pitchMidi] = note.timestamp;
    } else {
      midiNotes.push({
        pitchMidi: note.pitchMidi,
        startTimeSeconds: currentlyPressedNotes[note.pitchMidi],
        durationSeconds: note.timestamp - currentlyPressedNotes[note.pitchMidi],
        amplitude: 1,
      });
      currentlyPressedNotes[note.pitchMidi] = undefined;
    }
  }
  for (let pitchMidi in currentlyPressedNotes) {
    if (currentlyPressedNotes[pitchMidi] !== undefined) {
      midiNotes.push({
        pitchMidi: pitchMidi,
        startTimeSeconds: currentlyPressedNotes[pitchMidi],
        durationSeconds: now - currentlyPressedNotes[pitchMidi],
        amplitude: 1,
      });
    }
  }
  return midiNotes;
};

const getMicrophoneAccess = () => {
  // Check if getUserMedia is supported by the browser
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // Request microphone access
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        // Access to microphone granted
        console.log("Microphone access granted");
        // Remember to stop the stream to release the microphone when done
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(function (error) {
        // Access to microphone denied or an error occurred
        console.error("Microphone access denied:", error);
      });
    return true;
  } else {
    console.log("getUserMedia not supported by this browser");
    return false;
  }
};

const audioToMidi = async (
  audioBlob: Blob,
  modelname: string,
  onsetThresh: number = 0.5,
  frameThresh: number = 0.3,
  minNoteLen: number = 5
): Promise<NoteEventTime[]> => {
  const audioCtx = new AudioContext({ sampleRate: 22050 });
  let audioBuffer = undefined;
  let audioData;

  audioData = await audioBlob.arrayBuffer().catch((err) => {
    console.log("audioBlob.arrayBuffer()", err);
    return new ArrayBuffer(0);
  });

  audioCtx
    .decodeAudioData(
      // fs.readFileSync(filename,
      audioData,
      async (_audioBuffer) => {
        audioBuffer = _audioBuffer;
      },
      () => {}
    )
    .catch((err) => {
      console.log("decodeAudioData", err);
    });

  while (audioBuffer === undefined) {
    await new Promise((resolve) => setTimeout(resolve, 1));
  }

  const frames: number[][] = [];
  const onsets: number[][] = [];
  const contours: number[][] = [];
  let pct: number = 0;
  // modelname = './model.json'

  // modelname = 'https://piano-mp3.s3.us-east-2.amazonaws.com/model.json'
  // const model = tf.loadGraphModel(modelname)

  // const model = await tf.loadGraphModel(modelname);
  // const newmodel = await (await fetch(modelname)).json();
  // const jsonmodel = await newmodel.json()

  const basicPitch = new BasicPitch(modelname);
  audioBuffer = audioBuffer as unknown as AudioBuffer;
  const t0 = performance.now();
  await basicPitch.evaluateModel(
    audioBuffer,
    (f: number[][], o: number[][], c: number[][]) => {
      frames.push(...f);
      onsets.push(...o);
      contours.push(...c);
    },
    (p: number) => {
      pct = p;
    }
  );
  const notesPoly = outputToNotesPoly(frames, onsets, 0.5, 0.3, 5);
  const notes2 = noteFramesToTime(notesPoly);
  const notes = noteFramesToTime(
    addPitchBendsToNoteEvents(
      contours,
      outputToNotesPoly(frames, onsets, onsetThresh, frameThresh, minNoteLen)
    )
  );
  return notes;
};

type MidiPitchMap = { [key: number]: string };

const getMidiPitchMap = (): MidiPitchMap => {
  // const noteNames = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
  const noteNames = [
    "c",
    "c#",
    "d",
    "d#",
    "e",
    "f",
    "f#",
    "g",
    "g#",
    "a",
    "a#",
    "b",
  ];
  const midiPitchMap: MidiPitchMap = {};
  for (let i = 1; i < 10; i++) {
    for (let j = 0; j < noteNames.length; j++) {
      midiPitchMap[24 + 12 * (i - 1) + j] = `${noteNames[j]}/${i}`;
    }
  }
  return midiPitchMap;
};

const MIDI_PITCH_MAP: MidiPitchMap = getMidiPitchMap();
type StaffNote = { noteName: string; duration: number };
type VexStaffNote = { keys: string[]; duration: string; durationBeats: number };
type VexMap = { [key: number]: string };
const NOTE_LENGTH_VEX_FORMAT_MAP: VexMap = {
  0.25: "16",
  0.5: "8",
  0.75: "8.",
  1: "q",
  1.5: "q.",
  2: "h",
  3: "h.",
  4: "w",
};

const REST_LENGTH_VEX_FORMAT_MAP: VexMap = {
  0.25: "16/r",
  0.5: "8/r",
  0.75: "8/r.",
  1: "q/r",
  1.5: "q/r.",
  2: "h/r",
  3: "h/r.",
  4: "w/r",
};

// TODO: reverse the one above
const VEX_FORMAT_NOTE_LENGTH_MAP: any = {
  "16": 0.25,
  "8": 0.5,
  "8.": 0.75,
  q: 1,
  "q.": 1.5,
  h: 2,
  "h.": 3,
  w: 4,
};

const mergeMidiNotes = (
  notes: NoteEventTime[],
  minNoteLen: number,
  buffer: number
): NoteEventTime[] => {
  const mergedNotes: NoteEventTime[] = [];
  const pitches = new Set(notes.map((note) => note.pitchMidi));
  for (let pitchMidi of pitches) {
    const pitchNotes = notes.filter((note) => note.pitchMidi === pitchMidi);
    pitchNotes.sort((a, b) => a.startTimeSeconds - b.startTimeSeconds);
    let mergedPitchNotes: NoteEventTime[] = [];
    for (let i = 0; i < pitchNotes.length; i++) {
      if (mergedPitchNotes.length === 0) {
        mergedPitchNotes.push(pitchNotes[i]);
      } else {
        const lastNote = mergedPitchNotes[mergedPitchNotes.length - 1];
        const thisNote = pitchNotes[i];
        if (
          thisNote.startTimeSeconds <
          lastNote.startTimeSeconds + lastNote.durationSeconds + buffer
          // && thisNote.durationSeconds < minNoteLen
        ) {
          lastNote.durationSeconds =
            Math.max(
              lastNote.startTimeSeconds + lastNote.durationSeconds,
              thisNote.startTimeSeconds + thisNote.durationSeconds
            ) - lastNote.startTimeSeconds;
        } else {
          mergedPitchNotes.push(thisNote);
        }
      }
    }
    mergedNotes.push(...mergedPitchNotes);
  }
  return mergedNotes;
};

const computeAlignmentScoreMidi = (
  correctNotesMidi: any[],
  midi: any[],
  bpm: number,
  tolerance: number = 0
) => {
  if (tolerance === 0) {
    tolerance = 60 / bpm / 2; // within tolerance on either side
  }

  let score = 0;
  let answerNotesMidi = [];
  let missingNotesMidi = [];

  for (let correctNote of correctNotesMidi) {
    let found = false;
    for (let potentialMatch of midi) {
      if (
        correctNote.pitchMidi === potentialMatch.pitchMidi &&
        Math.abs(
          correctNote.startTimeSeconds - potentialMatch.startTimeSeconds
        ) < tolerance
      ) {
        score += 1;
        found = true;
        answerNotesMidi.push(correctNote);
        break;
      }
    }
    if (!found) {
      missingNotesMidi.push(correctNote);
    }
  }

  return {
    score: score,
    answerNotes: answerNotesMidi,
    missingNotesMidi: missingNotesMidi,
  };
};

const computeAlignmentScore = (
  correctNotes: any[],
  midi: any[],
  bpm: number,
  numNotes: number
) => {
  // this assume that the midi has been aligned correctly
  let staffNotes: StaffNote[][] = Array.from(Array(numNotes), () => []);
  for (let note of midi) {
    const noteDuration = Math.max(
      Math.round((note.durationSeconds * bpm) / 60),
      1
    );
    const startIndex = Math.round((note.startTimeSeconds * bpm) / 60);
    if (noteDuration > 0) {
      const staffNote = {
        noteName: MIDI_PITCH_MAP[note.pitchMidi],
        duration: noteDuration,
      };
      if (startIndex < staffNotes.length) {
        staffNotes[startIndex].push(staffNote);
      }
    }
  }
  const createNoteDurationMap = (notes: any[]) => {
    let noteDurationMap: any = {};
    for (let note of notes) {
      noteDurationMap[note.noteName] = note.duration;
    }
    return noteDurationMap;
  };
  const staffNotesByBeat = staffNotes.map((x) => createNoteDurationMap(x));
  let score = 0;
  let answerNotes = new Array(correctNotes.length);
  for (let i = 0; i < correctNotes.length; i++) {
    let answerBeat: StaffNote[] = [];
    for (let note of correctNotes[i].keys) {
      if (staffNotesByBeat[i][note] !== undefined) {
        score += 1;
        answerBeat.push({
          noteName: note,
          duration: staffNotesByBeat[i][note],
        });
      }
    }
    answerNotes[i] = answerBeat;
  }
  return { score: score, answerNotes: answerNotes };
};

const copyMidi = (midi: any[], shift: number) => {
  const newMidi = [];
  for (let note of midi) {
    if (note.startTimeSeconds >= shift) {
      newMidi.push({
        ...note,
        startTimeSeconds: note.startTimeSeconds - shift,
      });
    }
  }
  return newMidi;
};

const durationBeatsScaleFactors: any = {
  "4n": 1,
  "8n": 2,
  "16n": 4,
};

const convertTimeToBeats = (
  time: number,
  bpm: number,
  smallestNote: string
) => {
  const scaleFactor = durationBeatsScaleFactors[smallestNote];
  const preReturn = Math.round(((time * bpm) / 60) * scaleFactor) / scaleFactor;
  // TODO fix this hack after implement ties
  return preReturn;
};

// const getMissingNotesMidi = (
//   playedNotesMidi: any[],
//   correctNotesMidi: any[],
//   bpm: number
// ) => {
//   let missingNotesMidi: any[] = [];
//   let numberMissed = 0;

//   playedNotesMidi = copyMidi(playedNotesMidi, 0);
//   for (let correctNote of correctNotesMidi) {
//     let found = false;
//     for (let playedNote of playedNotesMidi) {
//       if (
//         correctNote.pitchMidi === playedNote.pitchMidi &&
//         Math.abs(correctNote.startTimeSeconds - playedNote.startTimeSeconds) <
//           60 / bpm / 2
//       ) {
//         found = true;
//         break;
//       }
//     }
//     if (!found) {
//       missingNotesMidi.push(correctNote);
//       numberMissed += 1;
//     }
//   }
//   return { missingNotesMidi: missingNotesMidi, numberMissed: numberMissed };
// };

const filterMidiToFirstNotesOneChannel = (midi: any[]) => {
  if (midi.length === 0) {
    return [];
  }
  const firstTime = Math.min(...midi.map((x) => x.startTimeSeconds));
  const firstNotes = midi.filter((x) => x.startTimeSeconds === firstTime);
  return firstNotes;
};

const filterMidiToFirstNotes = (midi: any[]) => {
  const [trebleMidi, bassMidi] = splitMidiTrebleBass(midi);
  const trebleFirstNotes = filterMidiToFirstNotesOneChannel(trebleMidi);
  const bassFirstNotes = filterMidiToFirstNotesOneChannel(bassMidi);
  return [...trebleFirstNotes, ...bassFirstNotes];
};

const convertMidiToVexBothClef = (
  midi: any[],
  bpm: number,
  smallestNote = "4n",
  key = "C",
  beatsPerMeasure = 4
) => {
  const [trebleMidi, bassMidi] = splitMidiTrebleBass(midi);
  const trebleVex = convertMidiToVex(
    trebleMidi,
    bpm,
    smallestNote,
    key,
    beatsPerMeasure
  );
  const bassVex = convertMidiToVex(
    bassMidi,
    bpm,
    smallestNote,
    key,
    beatsPerMeasure
  );
  while (trebleVex.length < bassVex.length) {
    trebleVex.push("B4/w/r");
  }
  while (bassVex.length < trebleVex.length) {
    bassVex.push("D3/w/r");
  }
  return { treble: trebleVex, bass: bassVex };
};

const isInKey = (pitchMidi: number, keyMidi: number) => {
  const naturals = [0, 2, 4, 5, 7, 9, 11];
  if (naturals.includes((pitchMidi - keyMidi) % 12)) {
    return true;
  }
  return false;
};

const isSharpKey = (keyMidi: number) => {
  if ([0, 2, 4, 7, 9, 11].includes(keyMidi % 12)) {
    return true;
  }
  return false;
};

const isSharpInKeyMidi = (pitchMidi: number, keyMidi: number) => {
  const keySharpMap = {
    0: [],
    [Midi.noteNameToMidi("G4") % 12]: ["F#4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("D4") % 12]: ["F#4", "C#4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("A4") % 12]: ["F#4", "C#4", "G#4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("E4") % 12]: ["F#4", "C#4", "G#4", "D#4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("B4") % 12]: ["F#4", "C#4", "G#4", "D#4", "A#4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("F#4") % 12]: [
      "F#4",
      "C#4",
      "G#4",
      "D#4",
      "A#4",
      "E#4",
    ].map((x) => Midi.noteNameToMidi(x) % 12),
    [Midi.noteNameToMidi("C#4") % 12]: [
      "F#4",
      "C#4",
      "G#4",
      "D#4",
      "A#4",
      "E#4",
      "B#4",
    ].map((x) => Midi.noteNameToMidi(x) % 12),
  };
  return keySharpMap[keyMidi % 12].includes(pitchMidi % 12);
};

const isSharpNaturalInKeyMidi = (pitchMidi: number, keyMidi: number) => {
  const keySharpMap = {
    0: [],
    [Midi.noteNameToMidi("G4") % 12]: ["F4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("D4") % 12]: ["F4", "C4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("A4") % 12]: ["F4", "C4", "G4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("E4") % 12]: ["F4", "C4", "G4", "D4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("B4") % 12]: ["F4", "C4", "G4", "D4", "A4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("F#4") % 12]: ["F4", "C4", "G4", "D4", "A4", "E4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("C#4") % 12]: [
      "F4",
      "C4",
      "G4",
      "D4",
      "A4",
      "E4",
      "B4",
    ].map((x) => Midi.noteNameToMidi(x) % 12),
  };
  return keySharpMap[keyMidi % 12].includes(pitchMidi % 12);
};

const isFlatInKeyMidi = (pitchMidi: number, keyMidi: number) => {
  const keyFlatMap = {
    [Midi.noteNameToMidi("F4") % 12]: ["Bb4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Bb4") % 12]: ["Bb4", "Eb4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Eb4") % 12]: ["Bb4", "Eb4", "Ab4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Ab4") % 12]: ["Bb4", "Eb4", "Ab4", "Db4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Db4") % 12]: ["Bb4", "Eb4", "Ab4", "Db4", "Gb4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Gb4") % 12]: [
      "Bb4",
      "Eb4",
      "Ab4",
      "Db4",
      "Gb4",
      "B4",
    ].map((x) => Midi.noteNameToMidi(x) % 12),
  };

  return keyFlatMap[keyMidi % 12].includes(pitchMidi % 12);
};

const isFlatNaturalInKeyMidi = (pitchMidi: number, keyMidi: number) => {
  const keyNaturalMap = {
    [Midi.noteNameToMidi("F4") % 12]: ["B4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Bb4") % 12]: ["B4", "E4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Eb4") % 12]: ["B4", "E4", "A4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Ab4") % 12]: ["B4", "E4", "A4", "D4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Db4") % 12]: ["B4", "E4", "A4", "D4", "G4"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
    [Midi.noteNameToMidi("Gb4") % 12]: ["B4", "E4", "A4", "D4", "G4", "C5"].map(
      (x) => Midi.noteNameToMidi(x) % 12
    ),
  };

  return keyNaturalMap[keyMidi % 12].includes(pitchMidi % 12);
};

const midiToVexNoteName = (midiNote: number, key: string): string => {
  const noteNames = [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ];
  let octave = Math.floor(midiNote / 12) - 1;
  let noteName = noteNames[midiNote % 12];

  if (isSharpKey(Midi.noteNameToMidi(key + "4"))) {
    if (isSharpInKeyMidi(midiNote, Midi.noteNameToMidi(key + "4"))) {
      noteName = noteNames[(midiNote - 1) % 12];
      return `${noteName}${octave}`;
    }
    if (isSharpNaturalInKeyMidi(midiNote, Midi.noteNameToMidi(key + "4"))) {
      return `${noteName}n${octave}`;
    }
  } else {
    if (isFlatInKeyMidi(midiNote, Midi.noteNameToMidi(key + "4"))) {
      noteName = noteNames[(midiNote + 1) % 12];
      if (key == "Gb" && noteName == "C") {
        octave += 1;
      }
      return `${noteName}${octave}`;
    }
    if (isFlatNaturalInKeyMidi(midiNote, Midi.noteNameToMidi(key + "4"))) {
      return `${noteName}n${octave}`;
    }
  }
  return `${noteName}${octave}`;
};

const preprocessMidiToRemoveTies = (
  midi: any[],
  bpm: number,
  beatsPerMeasure: number
) => {
  // if there is a note that goes beyond the measure boundary, cut it short
  const ogMidi = midi;
  // 1/16 of a beat as buffer in case it happens to before the start of the measure
  const buffer = 60 / bpm / 16;
  midi = midi.map((note) => {
    const startMeasure = Math.floor(
      ((note.startTimeSeconds + buffer) * bpm) / 60 / beatsPerMeasure
    );
    const endMeasure = Math.floor(
      ((note.startTimeSeconds + note.durationSeconds - buffer) * bpm) /
        60 /
        beatsPerMeasure
    );
    if (startMeasure === endMeasure) {
      return note;
    }
    const newDuration = Math.min(
      note.durationSeconds,
      ((endMeasure * 60) / bpm) * beatsPerMeasure - note.startTimeSeconds
    );
    return {
      ...note,
      durationSeconds: newDuration,
    };
  });
  // if there are notes playing at the same time, that don't have the same start time,
  //    cut the longer one so that it ends at the same time as the shorter one starts
  midi = midi.map((note) => {
    const notesAtSameTimeThatStartAfter = midi.filter(
      (x) =>
        x.startTimeSeconds > note.startTimeSeconds &&
        x.startTimeSeconds < note.startTimeSeconds + note.durationSeconds &&
        x.pitchMidi !== note.pitchMidi
    );
    if (notesAtSameTimeThatStartAfter.length > 0) {
      return {
        ...note,
        durationSeconds:
          Math.min(
            ...notesAtSameTimeThatStartAfter.map((x) => x.startTimeSeconds)
          ) - note.startTimeSeconds,
      };
    }
    return note;
  });
  return midi;
};

const addRests = (
  currentMeasure: any[],
  restPrefix: string,
  restLength: number
) => {
  while (
    REST_LENGTH_VEX_FORMAT_MAP[restLength] === undefined &&
    restLength > 0
  ) {
    for (let smallerLength of [4, 2, 1, 0.5, 0.25]) {
      if (restLength > smallerLength) {
        restLength -= smallerLength;
        currentMeasure.push(
          restPrefix + REST_LENGTH_VEX_FORMAT_MAP[smallerLength]
        );
        break;
      }
    }
  }
  if (restLength > 0) {
    currentMeasure.push(restPrefix + REST_LENGTH_VEX_FORMAT_MAP[restLength]);
  }
};

const convertMidiToVex = (
  midi: any[],
  bpm: number,
  smallestNote = "4n",
  key = "C",
  beatsPerMeasure = 4
) => {
  // TODO if treble is silent the whole time, but the bass is not, it'll fail
  if (midi.length === 0) {
    return [];
  }
  let restPrefix = midi[0].clef === "bass" ? "D3/" : "B4/";
  // restPrefix = "R/";
  midi = preprocessMidiToRemoveTies(midi, bpm, beatsPerMeasure);
  let beatNoteMap: any = {};
  for (let note of midi) {
    const startBeat = convertTimeToBeats(
      note.startTimeSeconds,
      bpm,
      smallestNote
    );
    if (beatNoteMap[startBeat] === undefined) {
      beatNoteMap[startBeat] = [];
    }
    beatNoteMap[startBeat].push(note);
  }
  // make everything on the same beat have the same duration
  for (let beat in beatNoteMap) {
    const minDuration = Math.min(
      ...beatNoteMap[beat].map((note: any) => note.durationSeconds)
    );
    let minDurationBeats = convertTimeToBeats(minDuration, bpm, smallestNote);
    // if (minDurationBeats == 0) {
    //   debugger;
    // }
    //hack TODO fix this
    if (minDurationBeats == 2.5) {
      minDurationBeats = 2;
    }

    beatNoteMap[beat] = beatNoteMap[beat].map((note: any) => {
      const durationBeatsString = NOTE_LENGTH_VEX_FORMAT_MAP[minDurationBeats];
      // if (durationBeatsString == undefined) {
      //   debugger;
      // }
      return {
        ...note,
        duration: minDuration,
        durationBeats: minDurationBeats,
        durationBeatsString: durationBeatsString,
      };
    });
  }
  // convert to vex notes
  let vexNotes: any[] = [];
  let beatList = Object.keys(beatNoteMap)
    .filter((x) => {
      return beatNoteMap[x][0].durationBeatsString !== undefined;
    })
    .map((x) => {
      return [parseFloat(x), beatNoteMap[x]];
    })
    .sort((a, b): any => {
      return a[0] - b[0];
    });

  for (let beat of beatList) {
    vexNotes.push({
      keys: beat[1].map((note: any) => midiToVexNoteName(note.pitchMidi, key)),
      duration: beat[1][0].duration, //beat[1][0] is first note on beat
      durationBeats: beat[1][0].durationBeats,
      startTimeBeat: beat[0],
    });
  }

  let vexStaveNotes: any[] = [];
  let measures: any[] = [];
  let currentMeasure: any[] = [];
  let nextMeasureStartBeat = beatsPerMeasure;

  let totalBeats = 0;
  if (beatList[0][0] > 0) {
    addRests(currentMeasure, restPrefix, beatList[0][0]);
    totalBeats += beatList[0][0];
  }

  // deal with the case that the first note is a whole rest
  if (totalBeats === nextMeasureStartBeat) {
    nextMeasureStartBeat += beatsPerMeasure;
    measures.push(currentMeasure.join(", "));
    currentMeasure = [];
  }

  for (let i = 0; i < vexNotes.length - 1; i++) {
    let toAdd = "";
    if (vexNotes[i].keys.length > 1) {
      toAdd = "(" + vexNotes[i].keys.join(" ") + ")/";
    } else {
      toAdd = vexNotes[i].keys[0] + "/";
    }
    toAdd = toAdd + NOTE_LENGTH_VEX_FORMAT_MAP[vexNotes[i].durationBeats];
    currentMeasure.push(toAdd);
    totalBeats += vexNotes[i].durationBeats;

    let emptyTimeToNextBeat =
      Math.min(vexNotes[i + 1].startTimeBeat, nextMeasureStartBeat) -
      (vexNotes[i].startTimeBeat + vexNotes[i].durationBeats);
    totalBeats += emptyTimeToNextBeat;
    addRests(currentMeasure, restPrefix, emptyTimeToNextBeat);

    if (totalBeats === nextMeasureStartBeat) {
      nextMeasureStartBeat += beatsPerMeasure;
      measures.push(currentMeasure.join(", "));
      currentMeasure = [];
      // handle the case that the first note of the next measure should be a rest
      if (
        i + 1 < vexNotes.length &&
        vexNotes[i + 1].startTimeBeat > totalBeats
      ) {
        addRests(
          currentMeasure,
          restPrefix,
          vexNotes[i + 1].startTimeBeat - totalBeats
        );
        totalBeats += vexNotes[i + 1].startTimeBeat - totalBeats;
      }
    }
  }
  // add last note and account for any final rests
  const i = vexNotes.length - 1;
  let toAdd = "";
  if (vexNotes[i].keys.length > 1) {
    toAdd = "(" + vexNotes[i].keys.join(" ") + ")/";
  } else {
    toAdd = vexNotes[i].keys[0] + "/";
  }
  currentMeasure.push(
    toAdd +
      NOTE_LENGTH_VEX_FORMAT_MAP[vexNotes[vexNotes.length - 1].durationBeats]
  );
  totalBeats += vexNotes[vexNotes.length - 1].durationBeats;
  let beatsRemaining = beatsPerMeasure - (totalBeats % beatsPerMeasure);
  if (beatsRemaining > 0 && beatsRemaining < beatsPerMeasure) {
    addRests(currentMeasure, restPrefix, beatsRemaining);
  }

  measures.push(currentMeasure.join(", "));

  return measures;
};

const postprocessML = (
  notes: NoteEventTime[],
  bpm: number,
  validPitchMidi: Set<number>,
  minAmplitude: number = 0.5,
  beatsPerMeasure = 4,
  mergeBuffer = 0.01,
  correctNotesMidi: any[] = [],
  key: string = "C"
): any => {
  // Warning! Right now only works for 4/4 time, and can't allow
  // any smaller notes than 1/4. TODO fix this.
  // Todo support other time signatures

  // create a copy of notes

  let numNotes, midi;
  let staffNotes: StaffNote[][] = [];

  let bestAlignmentScore = 0;
  let bestAlignmentTime = 0;
  let bestAnswerNotes = [];
  let bestMissingNotesMidi = correctNotesMidi;
  notes.sort((a, b) => a.startTimeSeconds - b.startTimeSeconds);
  // notes = notes.filter((x) => x.durationSeconds > 0.05);
  for (let note of notes) {
    let copyMidiNotes = copyMidi(notes, note.startTimeSeconds);
    const { score, answerNotes, missingNotesMidi } = computeAlignmentScoreMidi(
      correctNotesMidi,
      copyMidiNotes,
      bpm
    );
    if (score > bestAlignmentScore) {
      bestAlignmentScore = score;
      bestAlignmentTime = note.startTimeSeconds;
      bestAnswerNotes = answerNotes;
      bestMissingNotesMidi = missingNotesMidi;
    }
  }
  staffNotes = bestAnswerNotes;
  numNotes = bestAnswerNotes.length;

  // convert to vex notes
  let vexNotes: any[] = [];

  vexNotes = convertMidiToVex(staffNotes, bpm, "4n", key);
  if (false) {
    let vexNotes: VexStaffNote[] = Array(numNotes);
    let beatsOfNoteLeft: any = 0;
    for (let i = 0; i < staffNotes.length; i++) {
      const currentBeat = staffNotes[i];
      const beatsRemaining = beatsPerMeasure - (i % beatsPerMeasure);
      if (currentBeat.length > 0) {
        // hack to make everything fit within a measure
        // todo: add ties for ntoes that go between measures
        let numberBeatsBeforeNextNote = beatsRemaining;
        for (let j = 1; j <= beatsRemaining && i + j < staffNotes.length; j++) {
          if (staffNotes[i + j].length > 0) {
            numberBeatsBeforeNextNote = j;
            break;
          }
        }
        const durationBeats = Math.min(
          currentBeat[0].duration,
          beatsRemaining,
          numberBeatsBeforeNextNote
        );
        beatsOfNoteLeft = Math.max(durationBeats, beatsOfNoteLeft);
        vexNotes[i] = {
          keys: currentBeat.map((note) => note.noteName),
          duration: NOTE_LENGTH_VEX_FORMAT_MAP[durationBeats],
          durationBeats: durationBeats,
          // duration: "q",
        };
      }
      beatsOfNoteLeft -= 1;
    }

    // convert to StaveNotes
    // fill in empty notes with rests
    while (
      vexNotes.length > 0 &&
      vexNotes
        .slice(vexNotes.length - beatsPerMeasure, vexNotes.length)
        .every((x) => x === null)
    ) {
      vexNotes = vexNotes.slice(0, vexNotes.length - beatsPerMeasure);
    }
  }

  return { notes: vexNotes, missingNotesMidi: bestMissingNotesMidi };
};

export {
  audioToMidi,
  postprocessML,
  VEX_FORMAT_NOTE_LENGTH_MAP,
  checkWebglSupport,
  getMicrophoneAccess,
  convertVirtualPianoNotesToMidi,
  convertMidiToVex,
  // getMissingNotesMidi,
  convertMidiToVexBothClef,
  splitMidiTrebleBass,
  filterMidiToFirstNotes,
};
